import React from 'react';
import * as R from 'ramda';
import { useWindowSize } from 'react-use';
// components
import { Table } from '../../../components/table';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, IconWrapper } from '../../../ui';
// feature payroll
import PaymentInfo from './payment-info';
import FuelCardsTab from './fuel-cards-tab';
import TollChargesTab from './toll-charges-tab';
import PayrollChargesTab from './payroll-charges-tab';
import {
  expensesReport,
  dailyHoursReport,
  tollTableSettings,
  advancePaymentReport,
  expensesColumnSettings,
  dailyHoursTableSettings,
  dailyHoursColumnSettings,
  advancePaymentColumnSettings,
  payrollInvoicesTableSettings,
  payrollInvoicesColumnSettings,
} from '../settings/charges-table-settings';
//////////////////////////////////////////////////

const getRemoveRestoreColumn = (listName: string, removeOrRestore: Function) => ({
  width: 60,
  customComponent: ({ data }: Object) => {
    const text = G.ifElse(
      data.removed,
      G.getWindowLocale('actions:restore', 'RESTORE'),
      G.getWindowLocale('actions:remove', 'REMOVE'),
    );

    const color = G.getTheme(G.ifElse(
      data.removed,
      'colors.dark.blue',
      'colors.light.mainRed',
    ));

    return (
      <Box
        color={color}
        cursor='pointer'
        onClick={() => removeOrRestore(G.getGuidFromObject(data), listName)}
      >
        {text}
      </Box>
    );
  },
});

const ActiveTab = (props: Object) => {
  const {
    activeTab,
    collapsedView,
    handleOpenTrip,
    vendorFuelCards,
    vendorTollCharges,
    selectChargeListItem,
    removeOrRestoreChargeListItem,
  } = props;

  const { height } = useWindowSize();

  const maxHeight = collapsedView ? R.subtract(height, 550) : R.subtract(height, 220);

  if (R.equals(activeTab, 0)) {
    return <PayrollChargesTab {...props} withoutTotals={true} />;
  } else if (R.equals(activeTab, 1)) {
    return <FuelCardsTab {...props} maxHeight={maxHeight} omitReportFields={[GC.FIELD_DRIVER]} />;
  } else if (R.equals(activeTab, 2)) {
    return (
      <FuelCardsTab
        {...props}
        noActions={true}
        maxHeight={maxHeight}
        fuelCards={vendorFuelCards}
        omitReportFields={[GC.FIELD_DRIVER]}
      />
    );
  } else if (R.equals(activeTab, 3)) {
    return <TollChargesTab {...props} maxHeight={maxHeight} omitReportFields={[GC.FIELD_DRIVER]} />;
  } else if (R.equals(activeTab, 4)) {
    return (
      <TollChargesTab
        {...props}
        noActions={true}
        maxHeight={maxHeight}
        tollCharges={vendorTollCharges}
        omitReportFields={[GC.FIELD_DRIVER]}
      />
    );
  } else if (R.equals(activeTab, 5)) {
    const { advancePayments } = props;

    const columnSettings = R.assoc(
      'removeRestoreColumn',
      getRemoveRestoreColumn('advancePayments', removeOrRestoreChargeListItem),
      advancePaymentColumnSettings,
    );

    const allChecked = G.isAllChecked(advancePayments);

    const tableSettings = R.assoc('maxHeight', maxHeight, tollTableSettings);

    return (
      <Table
        allChecked={allChecked}
        itemList={advancePayments}
        report={advancePaymentReport}
        tableSettings={tableSettings}
        columnSettings={columnSettings}
        onOptionClick={(guid: string) => selectChargeListItem(guid, 'advancePayments')}
      />
    );
  } else if (R.equals(activeTab, 6)) {
    const { expenses } = props;

    const columnSettings = R.assoc(
      'removeRestoreColumn',
      getRemoveRestoreColumn('expenses', removeOrRestoreChargeListItem),
      expensesColumnSettings,
    );

    const allChecked = G.isAllChecked(expenses);

    const tableSettings = R.assoc('maxHeight', maxHeight, tollTableSettings);

    return (
      <Table
        itemList={expenses}
        allChecked={allChecked}
        report={expensesReport}
        tableSettings={tableSettings}
        columnSettings={columnSettings}
        onOptionClick={(guid: string) => selectChargeListItem(guid, 'expenses')}
      />
    );
  } else if (R.equals(activeTab, 7)) {
    const { dailyHours, handleClickDriverDailyHoursActions } = props;

    const elementActionsComponent = (data: Object) => (
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickDriverDailyHoursActions(e, data)}>
        {I.threeDots()}
      </IconWrapper>
    );

    const tableSettings = R.assoc('maxHeight', maxHeight, dailyHoursTableSettings);

    return (
      <Table
        tableWrapperPB={40}
        itemList={dailyHours}
        report={dailyHoursReport}
        tableSettings={tableSettings}
        columnSettings={dailyHoursColumnSettings}
        renderRightStickedComponent={elementActionsComponent}
      />
    );
  } else if (R.equals(activeTab, 8)) {
    const { payrollInvoices, handleEditDriverInvoice, handleRemoveInvoiceFromPayroll } = props;

    const getPermissions = ({ completed }: Object) => G.ifElse(
      G.isTrue(completed),
      [PC.TEL_INVOICE_OVERWRITE_EXECUTE],
      [PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_INVOICE_OVERWRITE_EXECUTE],
    );

    const actionButtons = [
      {
        getPermissions,
        iconName: 'pencil',
        action: handleEditDriverInvoice,
      },
      {
        getPermissions,
        iconName: 'trash',
        action: handleRemoveInvoiceFromPayroll,
      },
    ];

    const report = G.getReportFromColumnSettings(payrollInvoicesColumnSettings);

    const tableSettings = R.assoc('maxHeight', maxHeight, payrollInvoicesTableSettings);

    return (
      <Table
        report={report}
        itemList={payrollInvoices}
        actionButtons={actionButtons}
        tableSettings={tableSettings}
        callbackData={{ handleOpenTrip }}
        columnSettings={payrollInvoicesColumnSettings}
      />
    );
  }

  return <PaymentInfo {...props} />;
};

export default ActiveTab;

export {
  getRemoveRestoreColumn,
};
