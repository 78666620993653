import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useMedia, useScroll, useWindowSize } from 'react-use';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
import React, { useRef, Fragment, useState, useEffect, useCallback } from 'react';
// components
import { Tabs2, withTabs2, ActionBox, FormFooter2, getConfirmModal } from '../../../../components';
// features
import PC from '../../../permission/role-permission';
import { makeSelectAuthorities } from '../../../permission/selectors';
import { setExpandedContainerOptions } from '../../../expanded-container/actions';
// forms
import { PureStyledForm } from '../../../../forms';
import { Fieldset2 } from '../../../../forms/formik';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex, Text, RelativeFlex, SectionsDivider, scrollableContainerCss3px } from '../../../../ui';
// hocs
import {
  withFixedPopover,
  withAsyncConfigs,
  withAsyncAccessorials,
  withAsyncInvoiceStatusConfigs,
  withAsyncInitialDataOnDidMount,
  withAsyncGetGLCodeMappingsByScope,
} from '../../../../hocs';
import { withAsyncEditDriverInvoice } from '../../../../hocs/with-async-edit-driver-invoice';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature payroll
import * as H from '../../helpers';
import ActiveTab from './active-tab';
import { PayrollTotals } from './payroll-totals';
import { FIELD_MAIN_CHARGES } from '../../constants';
import { withFuelCards } from '../../hooks/use-fuel-cards';
import AddAdditionalCharges from './add-additional-charges';
import { makeSelectRecallDocumentsGuid } from '../selectors';
import InvoicesTotals from '../../components/invoices-totals';
import { withTollCharges } from '../../hooks/use-toll-charges';
import PayrollDocuments from '../../components/payroll-documents';
import { PayrollHeaderActions } from '../../components/payroll-actions';
import { removeInvoiceFromPayroll, updateVendorInvoiceRequest } from '../actions';
import { withAsyncPayrollCharges } from '../../hocs/with-async-vendor-payroll-charges';
import {
  vendorTabsOptions,
  payrollWithChargesFieldsetSettings,
} from '../../settings/payroll-with-charges-settings';
import DriverPayrollWithCharges, {
  ViewButton,
  //
  COLLAPSED,
  FULL_SCREEN,
  setOptionsForSelect,
  setEditDefaultValues,
  validationSchemaObject,
  mapPayrollChargesWithSequenceNum,
} from '../../components/payroll-with-charges';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const darkBlue = G.getTheme('colors.dark.blue');
const lightGreyColor = G.getTheme('colors.light.lightGrey');
const darkMainDarkColor = G.getTheme('colors.dark.mainDark');

const configsNamesArray = [GC.INVOICE_GL_CODE];

const PayrollWithCharges = (props: Object) => {
  const {
    values,
    activeTab,
    vendorName,
    payrollGuid,
    authorities,
    handleSubmit,
    fleetVendorGuid,
    payrollInvoices,
    handleSetActiveTab,
    getPayrollStatistic,
    mailIntegrationType,
    handleUpdateInvoices,
    handleSaveWithoutClose,
    handleAddAdditionalCharges,
    setExpandedContainerOptions,
  } = props;

  const recallDocumentsGuid = useSelector(makeSelectRecallDocumentsGuid());

  const [chargesView, setChargesView] = useState(COLLAPSED);

  const scrollRef = useRef(null);

  const { x } = useScroll(scrollRef);

  const isLarge = useMedia('(min-width: 1570px)');

  const { width } = useWindowSize();

  const widthToUse = R.add(R.subtract(width, 3), x);

  useEffect(() => {
    const fetchSettings = async () => {
      const setting = await G.getIndexedDBItem(GC.AmousUIIdbProps, GC.vendorPayrollEditPayrollChargesViewIdbId);

      if (setting) {
        const { value } = setting;

        setChargesView(value);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    getPayrollStatistic();
  }, []);

  const collapsedView = R.equals(chargesView, COLLAPSED);

  const payrollNumber = R.prop(GC.FIELD_PAYROLL_NUMBER, values);

  const optionsForSelect = setOptionsForSelect(props);

  const statusText = R.compose(
    R.path([G.getPropFromObject(GC.FIELD_STATUS, values), 'label']),
    G.indexByValue,
    R.pathOr([], [GC.FIELD_STATUS]),
  )(optionsForSelect);

  const glDisabled = G.notContain(PC.GL_CODE_WRITE, authorities);

  const handleOpenDriverProfile = useCallback((visitPageGuid: string) => {
    setExpandedContainerOptions({
      opened: true,
      zIndex: 1301,
      visitPageGuid,
      componentType: GC.PAGE_FLEET_DRIVER_PROFILE_V2,
    });
  }, []);

  const handleOpenVendorProfile = useCallback((visitPageGuid: string) => {
    setExpandedContainerOptions({
      opened: true,
      zIndex: 1301,
      visitPageGuid,
      componentType: GC.PAGE_FLEET_VENDOR_PROFILE_V2,
    });
  }, []);

  const handleOpenTrip = useCallback((visitPageGuid: string) => {
    setExpandedContainerOptions({
      opened: true,
      zIndex: 1301,
      visitPageGuid,
      componentType: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
    });
  }, []);

  return (
    <Fragment>
      <Flex px={15} height={60} justifyContent='space-between'>
        <Flex>
          { payrollNumber && (
            <Text
              mr={15}
              fontSize={16}
              fontWeight='bold'
              color={darkMainDarkColor}
            >
              {`${G.getWindowLocale('titles:payroll', 'Payroll')} #: ${payrollNumber}`}
            </Text>
          )}
          {
            G.isNotNilAndNotEmpty(statusText) &&
            <Box
              mr={15}
              p='5px'
              borderRadius='3px'
              color={whiteColor}
              bg={G.getTheme('colors.mustardYellow')}
            >
              {statusText}
            </Box>
          }
          <Flex>
            <Text mr='5px'>{`${G.getWindowLocale('titles:vendor', 'Vendor')}:`}</Text>
            <ActionBox
              text={vendorName}
              action={() => handleOpenVendorProfile(fleetVendorGuid)}
              title={G.getWindowLocale('titles:open-profile', 'Open Profile')}
            />
          </Flex>
        </Flex>
        <PayrollHeaderActions {...props} isVendor={true} />
      </Flex>
      <SectionsDivider borderColor={lightGreyColor} />
      <PureStyledForm onSubmit={handleSubmit}>
        <Box
          ref={scrollRef}
          overflow='auto'
          height='calc(100vh - 120px)'
          css={scrollableContainerCss3px}
          borderBottom={`1px solid ${lightGreyColor}`}
        >
          {
            collapsedView && (
              <RelativeFlex
                p={15}
                alignItems='flex-start'
                bg={G.getTheme('colors.#EDEFF1')}
                width={isLarge ? widthToUse : 'max-content'}
              >
                <Box
                  p={15}
                  pt={25}
                  mr={15}
                  width={350}
                  flexShrink={0}
                  bg={whiteColor}
                  borderRadius='6px'
                >
                  <Fieldset2
                    {...G.getFormikProps(props)}
                    {...optionsForSelect}
                    grouped={true}
                    fields={payrollWithChargesFieldsetSettings}
                    handlers={{
                      handleDisableGlCode: () => props.glDisabled,
                    }}
                  />
                </Box>
                <PayrollTotals {...props} />
                <PayrollDocuments
                  payrollGuid={payrollGuid}
                  recallDocumentsGuid={recallDocumentsGuid}
                  mailIntegrationType={mailIntegrationType}
                  documentsType={GC.DOCUMENTS_TYPE_VENDOR_PAYROLL}
                />
              </RelativeFlex>
            )
          }
          <Flex pr={15} width={widthToUse} justifyContent='space-between'>
            <Tabs2
              activeTab={activeTab}
              tabs={vendorTabsOptions}
              setActiveTab={handleSetActiveTab}
              tabStyles={GC.COMMON_MUI_TAB_STYLES}
              tabsStyles={GC.COMMON_MUI_TABS_STYLES}
            />
            {
              collapsedView &&
              <ViewButton
                view={FULL_SCREEN}
                setView={setChargesView}
                id={GC.vendorPayrollEditPayrollChargesViewIdbId}
                title={G.getWindowLocale('actions:expand', 'Expand')}
              />
            }
            {
              R.not(collapsedView) &&
              <ViewButton
                view={COLLAPSED}
                setView={setChargesView}
                id={GC.vendorPayrollEditPayrollChargesViewIdbId}
                title={G.getWindowLocale('actions:collapse', 'Collapse')}
              />
            }
          </Flex>
          <ActiveTab
            {...props}
            glDisabled={glDisabled}
            widthToUse={widthToUse}
            collapsedView={collapsedView}
            handleOpenTrip={handleOpenTrip}
            handleOpenDriverProfile={handleOpenDriverProfile}
          />
          {
            R.includes(activeTab, [1, 2, 3, 4]) &&
            <Box
              p='4px 8px'
              m='6px 16px'
              color={darkBlue}
              cursor='pointer'
              borderRadius='4px'
              width='fit-content'
              bgColor={whiteColor}
              border={`1px solid ${darkBlue}`}
              onClick={() => handleAddAdditionalCharges(vendorTabsOptions[activeTab].text)}
            >
              {G.getWindowLocale('titles:add-additional', 'Add Additional')}
            </Box>
          }
          {
            R.equals(activeTab, 6) &&
            <Flex>
              <Box
                p='4px 8px'
                m='6px 16px'
                color={darkBlue}
                cursor='pointer'
                borderRadius='4px'
                width='fit-content'
                bgColor={whiteColor}
                onClick={handleUpdateInvoices}
                border={`1px solid ${darkBlue}`}
              >
                {G.getWindowLocale('actions:update-invoices', 'Update Invoices')}
              </Box>
              <InvoicesTotals
                isVendor={true}
                list={payrollInvoices}
                payrollCurrency={R.pathOr(
                  GC.DEFAULT_UI_CURRENCY,
                  [GC.FIELD_CURRENCY],
                  values,
                )}
              />
            </Flex>
          }
        </Box>
        <FormFooter2
          boxStyles={{ p: 15, bottom: 0 }}
          submitBtnStyles={{ width: 'auto' }}
          submitBtnText={G.getWindowLocale('actions:save-and-close', 'Save And Close')}
          actionButtons={[
            {
              action: () => handleSaveWithoutClose('prev'),
              buttonStyles: { mr: 10, ml: 'auto', width: 'max-content' },
              displayText: G.getWindowLocale('actions:save-next', 'Save/Prev'),
            },
            {
              action: handleSaveWithoutClose,
              buttonStyles: { mr: 10, width: 'max-content' },
              displayText: G.getWindowLocale('actions:save-next', 'Save/Next'),
            },
            {
              action: () => handleSaveWithoutClose('stay'),
              buttonStyles: { mr: 10, width: 'max-content' },
              displayText: G.getWindowLocale('actions:save', 'Save'),
            },
          ]}
        />
      </PureStyledForm>
    </Fragment>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  authorities: makeSelectAuthorities(state),
});

const enhance = compose(
  connect(mapStateToProps, {
    removeInvoiceFromPayroll,
    updateVendorInvoiceRequest,
    setExpandedContainerOptions,
  }),
  withFixedPopover,
  withAsyncPayrollCharges,
  withFuelCards({ payrollGuidParam: 'vendorPayrollGuid' }),
  withTollCharges({ payrollGuidParam: 'vendorPayrollGuid' }),
  withTabs2,
  withAsyncInitialDataOnDidMount,
  withHandlers({
    updateDriverInvoiceRequest: (props: Object) => (data: Object) => {
      const {
        getPayrollInvoices,
        getInitialDataRequest,
        updateVendorInvoiceRequest,
      } = props;

      const { invoice } = data;

      const { fleetVendorPayrollGuid } = invoice;

      const callback = () => {
        getPayrollInvoices({ payrollGuid: fleetVendorPayrollGuid });

        getInitialDataRequest();
      };

      updateVendorInvoiceRequest({ ...data, callback });
    },
  }),
  withAsyncEditDriverInvoice({ updateFrom: 'payrolls' }),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaObject,
    mapPropsToValues: (props: Object) => setEditDefaultValues(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const {
        expenses,
        fuelCards,
        tollCharges,
        driverPayrolls,
        handleUpdatePayroll,
      } = props;

      const fleetVendorPayroll = R.compose(
        mapPayrollChargesWithSequenceNum,
        G.mapFormFieldsToNull([GC.FIELD_INVOICE_STATUS]),
        R.omit([
          FIELD_MAIN_CHARGES,
          GC.FIELD_PAYROLL_DEDUCTION_SUBTOTAL,
          GC.FIELD_PAYROLL_DEDUCTION_CHARGES_TOTAL,
        ]),
        R.map((item: any) => G.checkAndConvertMomentInstanceToFormattedDate(item, GC.DEFAULT_DATE_FORMAT)),
      )(values);

      handleUpdatePayroll({
        shouldClose: true,
        fleetVendorPayroll,
        fuelCards: H.getFuelCardsData(fuelCards),
        tollCharges: H.getChargesData(tollCharges),
        driverExpenses: H.getChargesData(expenses),
        driverPayrolls: H.getDriverPayrollsData(driverPayrolls),
      });
    },
    displayName: 'PayrollForm',
  }),
  withProps(() => ({ isEditMode: false, configsNamesArray })),
  withAsyncConfigs,
  withAsyncAccessorials,
  withAsyncInvoiceStatusConfigs,
  withAsyncGetGLCodeMappingsByScope(GC.INVOICE_SCOPE_TYPE_DRIVER),
  withHandlers({
    handleSaveWithoutClose: (props: Object) => (nextType: string) => {
      const {
        values,
        expenses,
        fuelCards,
        tollCharges,
        driverPayrolls,
        setPrevPayrollGuid,
        setNextPayrollGuid,
        handleUpdatePayroll,
        getInitialDataRequest,
      } = props;

      const stay = R.equals(nextType, 'stay');

      let callback = setNextPayrollGuid;

      if (stay) {
        callback = getInitialDataRequest;
      } else if (R.equals(nextType, 'prev')) {
        callback = setPrevPayrollGuid;
      }

      const fleetVendorPayroll = R.compose(
        mapPayrollChargesWithSequenceNum,
        G.mapFormFieldsToNull([GC.FIELD_INVOICE_STATUS]),
        R.omit([
          FIELD_MAIN_CHARGES,
          GC.FIELD_PAYROLL_DEDUCTION_SUBTOTAL,
          GC.FIELD_PAYROLL_DEDUCTION_CHARGES_TOTAL,
        ]),
        R.map((item: any) => G.checkAndConvertMomentInstanceToFormattedDate(item, GC.DEFAULT_DATE_FORMAT)),
      )(values);

      handleUpdatePayroll({
        callback,
        fleetVendorPayroll,
        shouldClose: R.not(stay),
        fuelCards: H.getFuelCardsData(fuelCards),
        mapDataToFormValues: setEditDefaultValues,
        tollCharges: H.getChargesData(tollCharges),
        driverExpenses: H.getChargesData(expenses),
        driverPayrolls: H.getDriverPayrollsData(driverPayrolls),
      });
    },
    handleUpdateDriverPayroll: (props: Object) => async (values: Object) => {
      const {
        openLoader,
        closeModal,
        closeLoader,
        payrollGuid,
        getDriverPayrolls,
        getInitialDataRequest,
      } = props;

      openLoader({ showDimmer: true });

      const options = {
        data: R.omit(['shouldClose'], values),
      };

      const res = await sendRequest('put', endpointsMap.driverPayroll, options);

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        getDriverPayrolls({ payrollGuid });
        getInitialDataRequest();

        closeModal();
      } else {
        G.handleFailResponseSimple(res, 'handleUpdateDriverPayroll fail');
      }

      closeLoader();
    },
  }),
  withHandlers({
    handleAddAdditionalCharges: (props: Object) => (title: string) => {
      const {
        expenses,
        fuelCards,
        activeTab,
        openModal,
        closeModal,
        branchGuid,
        tollCharges,
        driverPayrolls,
        addChargesToList,
        asyncInitialData,
        initialFilterDates,
        addChargesToFuelCards,
        addChargesToTollCharges,
      } = props;

      const vendorGuid = R.path(['data', GC.FIELD_FLEET_VENDOR_GUID], asyncInitialData);

      const tabsData = {
        1: {
          list: fuelCards,
          listName: 'fuelCards',
          endpoint: endpointsMap.fuelCardListForVendorPayroll,
        },
        2: {
          list: tollCharges,
          listName: 'tollCharges',
          endpoint: endpointsMap.tollChargesListForVendorPayroll,
        },
        3: {
          list: driverPayrolls,
          listName: 'driverPayrolls',
          endpoint: endpointsMap.driverPayrollsForVendorPayroll,
        },
        4: {
          list: expenses,
          listName: 'expenses',
          endpoint: endpointsMap.driverExpenseListForVendorPayroll,
        },
      };

      const asyncOptions = {
        data: {
          ...initialFilterDates,
          [GC.FIELD_BRANCH_GUID]: branchGuid,
          [GC.FIELD_VENDOR_GUID]: vendorGuid,
        },
      };

      const existedList = R.compose(
        R.map(G.getGuidFromObject),
        R.path([activeTab, 'list']),
      )(tabsData);

      const listName = R.path([activeTab, 'listName'], tabsData);

      const component = (
        <AddAdditionalCharges
          asyncMethod='post'
          openModal={openModal}
          activeTab={activeTab}
          closeModal={closeModal}
          branchGuid={branchGuid}
          vendorGuid={vendorGuid}
          existedList={existedList}
          asyncOptions={asyncOptions}
          initialFilterDates={initialFilterDates}
          asyncEndpoint={R.path([activeTab, 'endpoint'], tabsData)}
          addChargesToList={(charges: Array) => {
            if (R.equals(listName, 'fuelCards')) {
              addChargesToFuelCards(charges);
            } else if (R.equals(listName, 'tollCharges')) {
              addChargesToTollCharges(charges);
            } else {
              addChargesToList(charges, listName);
            }

            closeModal();
          }}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          title,
          width: 1100,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '95vh',
        },
      };

      openModal(modal);
    },
    handleRemoveInvoiceFromPayroll: (props: Object) => (
      invoiceGuid: string,
      invoice: Object,
    ) => {
      const {
        openModal,
        closeModal,
        getPayrollInvoices,
        getInitialDataRequest,
        removeInvoiceFromPayroll,
      } = props;

      const { invoiceNumber, fleetVendorPayrollGuid } = invoice;

      const callback = () => {
        getPayrollInvoices({ payrollGuid: fleetVendorPayrollGuid });

        getInitialDataRequest();
      };

      const modalContent = getConfirmModal({
        name: invoiceNumber,
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale('messages:delete-confirmation-text-double', 'Are you sure you want to delete'),
        submitAction: () => {
          removeInvoiceFromPayroll({ callback, invoiceGuid, fleetVendorPayrollGuid });
          closeModal();
        },
      });

      openModal(modalContent);
    },
    handleEditDriverPayroll: (props: Object) => (driverPayrollGuid: string, payroll: Object) => {
      const {
        openModal,
        closeModal,
        branchGuid,
        openLoader,
        closeLoader,
        payrollGuid,
        getDriverPayrolls,
        getItemListRequest,
        handleShowDocuments,
        mailIntegrationType,
        getInitialDataRequest,
        resetListAndPagination,
        handleUpdateDriverPayroll,
        handleOpenUpdatePayrollInvoices,
      } = props;

      const {
        driverGuid,
        driverLoginId,
        driverLastName,
        driverFirstName,
      } = payroll;

      const endpoint = endpointsMap.getDriverPayrollDetailsEndpoint(driverPayrollGuid);

      const initialFilterDates = R.pick([GC.FIELD_PAYROLL_DATE_TO, GC.FIELD_PAYROLL_DATE_FROM], payroll);

      const driverText = `${
        R.or('', driverFirstName)} ${
        R.or('', driverLastName)} (${
        driverLoginId
      })`;

      const onChangeCallback = () => {
        getDriverPayrolls({ payrollGuid });

        getInitialDataRequest();
      };

      const component = (
        <DriverPayrollWithCharges
          type='driver'
          editMode={true}
          openModal={openModal}
          driverText={driverText}
          driverGuid={driverGuid}
          closeModal={closeModal}
          openLoader={openLoader}
          branchGuid={branchGuid}
          asyncEndpoint={endpoint}
          fromVendorPayroll={true}
          closeLoader={closeLoader}
          payrollGuid={driverPayrollGuid}
          onChangeCallback={onChangeCallback}
          initialFilterDates={initialFilterDates}
          getItemListRequest={getItemListRequest}
          mailIntegrationType={mailIntegrationType}
          resetListAndPagination={resetListAndPagination}
          handleSendDriverPayroll={handleUpdateDriverPayroll}
          handleOpenUpdatePayrollInvoices={
            (callback: Function) => handleOpenUpdatePayrollInvoices({ payroll, callback })
          }
          handleShowDocuments={() => handleShowDocuments(
            G.getGuidFromObject(payroll),
            GC.DOCUMENTS_TYPE_DRIVER_PAYROLL,
            driverGuid,
          )}
        />
      );

      const modal = G.getFullWindowModalWithContentWithLeftSpace(component, 100);

      openModal(modal);
    },
    handleUpdateInvoices: (props: Object) => () => {
      const {
        payrollGuid,
        onChangeCallback,
        getPayrollInvoices,
        getInitialDataRequest,
        handleOpenUpdatePayrollInvoices,
      } = props;

      const callback = () => {
        getPayrollInvoices({ payrollGuid });

        getInitialDataRequest();

        G.callFunction(onChangeCallback);
      };

      handleOpenUpdatePayrollInvoices(callback);
    },
  }),
  pure,
);

export default enhance(PayrollWithCharges);
