import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
// features
import { getBranchConfigsByNamesSuccess } from '../branch/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature new-do
import * as A from './actions';
import { isValidCustomerRateForm } from './validation';
import {
  isPageCreateDO,
  getPrimaryReference,
  isSourceTypeEdiOrManual,
  createCustomerLoadNumber,
  getRateWithChargesFromData,
  getDivisionGuidByBranchConfigs,
  getStopPointsLatLonStringsArray,
} from './helpers';
import {
  TAB_NAME_BILL_TO,
  PAGE_TYPE_CREATE_DO,
  TAB_NAME_CLO_SUMMARY,
  PAGE_TYPE_CLO_TEMPLATE,
  PAGE_TYPE_LOAD_STOPS_EDIT,
  PAGE_TYPE_CREATE_FROM_EDI,
  PAGE_TYPE_CREATE_FROM_QUOTE,
  PAGE_TYPE_CREATE_FROM_PUBLIC_CLO,
  PAGE_TYPE_CREATE_FROM_ORDER_QUOTE,
} from './constants';
import {
  getNewItemFields,
  defaultBillToFields,
  getDefaultDropFields,
  allCustomerRateFields,
  getNewContainerFields,
  getDefaultPickupFields,
} from './settings/fields-settings';
//////////////////////////////////////////////////

const referenceFormInitData = {
  isValid: false,
  [GC.FIELD_LOAD_SERVICES]: [],
  [GC.FIELD_LOAD_REFERENCES]: [],
  [GC.FIELD_LOAD_EQUIPMENT]: null,
  [GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS]: null,
};

const staticData = {
  branchList: [],
  fromPage: null,
  loadGuid: null,
  hotOrder: false,
  orderType: null,
  pinnedNote: null,
  branchRefTypes: {},
  divisionPrefix: '',
  divisionGuid: null,
  branchConfigs: null,
  telDriverRate: null,
  telCarrierRate: null,
  ignoreWarnings: false,
  saveAndDuplicateQty: 0,
  validationErrors: null,
  customerLoadNumber: '',
  stopPointsStrings: null,
  routeTemplateGuid: null,
  primaryRefSequence: null,
  validationWarnings: null,
  customerReferenceValue: '',
  sharedAccessorialsList: [],
  loadType: GC.LOAD_TYPE_CLO,
  pageType: PAGE_TYPE_CREATE_DO,
  leftActiveTad: TAB_NAME_BILL_TO,
  branchInfo: GC.EMPTY_OPTION_OBJECT,
  rightActiveTad: TAB_NAME_CLO_SUMMARY,
  primaryReference: GC.EMPTY_OPTION_OBJECT,
  referenceFormData: referenceFormInitData,
  // For Containers
  useContainers: false,
  branchDefaultItem: null,
  addedNewFirstDrop: false,
  addedNewFirstPickup: false,
  autoCreateContainerItem: false,
};

const createDOData = {
  rateBackup: null,
  [GC.FIELD_RATE]: null,
  ratePreviewFiles: null,
  pageType: PAGE_TYPE_CREATE_DO,
  [GC.FIELD_LOAD_NUMBER_OF_LOADS]: 1,
  [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
  telCreationMode: GC.TEL_CREATION_MODE_SINGLE_TEL,
  [GC.SYSTEM_OBJECT_BILL_TO]: R.assoc('isValid', false, defaultBillToFields),
  //
  aiRecognize: {
    data: null,
    loading: false,
    parseDocument: null,
  },
};

const templateData = {
  rateBackup: null,
  [GC.FIELD_RATE]: null,
  ratePreviewFiles: null,
  pageType: PAGE_TYPE_CLO_TEMPLATE,
  [GC.FIELD_LOAD_NUMBER_OF_LOADS]: 1,
  [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
  telCreationMode: GC.TEL_CREATION_MODE_SINGLE_TEL,
  [GC.SYSTEM_OBJECT_BILL_TO]: R.assoc('isValid', false, defaultBillToFields),
};

const createFromEDIData = {
  ediData: null,
  ediFile: null,
  rateBackup: null,
  [GC.FIELD_RATE]: null,
  [GC.FIELD_LOAD_NUMBER_OF_LOADS]: 1,
  [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
  [GC.FIELD_LOAD_TEL_CREATION_MODE]: GC.TEL_CREATION_MODE_SINGLE_TEL,
  [GC.SYSTEM_OBJECT_BILL_TO]: R.assoc('isValid', false, defaultBillToFields),
};

const createFromPublicCloData = {
  rateBackup: null,
  publicCloData: null,
  [GC.FIELD_RATE]: null,
  [GC.FIELD_LOAD_NUMBER_OF_LOADS]: 1,
  [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
  pageType: PAGE_TYPE_CREATE_FROM_PUBLIC_CLO,
  [GC.FIELD_LOAD_TEL_CREATION_MODE]: GC.TEL_CREATION_MODE_SINGLE_TEL,
  [GC.SYSTEM_OBJECT_BILL_TO]: R.assoc('isValid', false, defaultBillToFields),
};

const createFromOrderQuoteData = {
  orderQuoteData: {},
  [GC.FIELD_RATE]: null,
  [GC.FIELD_LOAD_NUMBER_OF_LOADS]: 1,
  [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
  pageType: PAGE_TYPE_CREATE_FROM_ORDER_QUOTE,
  [GC.FIELD_LOAD_TEL_CREATION_MODE]: GC.TEL_CREATION_MODE_SINGLE_TEL,
  [GC.SYSTEM_OBJECT_BILL_TO]: R.assoc('isValid', false, defaultBillToFields),
};

const createFromQuoteData = {
  quoteData: null,
  rateBackup: null,
  [GC.FIELD_RATE]: null,
  [GC.FIELD_LOAD_NUMBER_OF_LOADS]: 1,
  pageType: PAGE_TYPE_CREATE_FROM_QUOTE,
  [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
  [GC.FIELD_LOAD_TEL_CREATION_MODE]: GC.TEL_CREATION_MODE_SINGLE_TEL,
  [GC.SYSTEM_OBJECT_BILL_TO]: R.assoc('isValid', false, defaultBillToFields),
};

const createNewDOPickup = (order: number) => ({
  order,
  isValid: false,
  id: G.generateGuid(),
  [GC.FIELD_DISTANCE_CLO]: null,
  formData: getDefaultPickupFields(),
  [GC.FIELD_EVENT_TYPE]: GC.EVENT_TYPE_PICKUP,
});

const createNewDODrop = (order: number) => ({
  order,
  isValid: false,
  id: G.generateGuid(),
  [GC.FIELD_DISTANCE_CLO]: null,
  formData: getDefaultDropFields(),
  [GC.FIELD_EVENT_TYPE]: GC.EVENT_TYPE_DROP,
});

const createExistedStopData = ({ event, loadType }: Object) => {
  const eventType = R.prop(GC.FIELD_EVENT_TYPE, event);
  const orderProp = G.getEventIndexPropByLoadType(loadType);
  const distanceProp = G.getDistancePropByLoadType(loadType);
  const order = R.path([orderProp], event);
  const originDistance = R.path([distanceProp], event);
  const defaultFormFields = G.ifElse(
    G.isEventTypePickup(eventType),
    getDefaultPickupFields,
    getDefaultDropFields,
  )();

  return {
    order,
    eventType,
    isValid: false,
    originEvent: event,
    id: G.generateGuid(),
    formData: defaultFormFields,
    [distanceProp]: originDistance,
  };
};

const createNewStop = (stopType: string, stopOrder: string, store: Object) => {
  if (R.equals(stopType, GC.EVENT_TYPE_PICKUP)) {
    const newStop = createNewDOPickup(stopOrder);
    const withDates = G.getBranchNewStopDateTimeDefaultValues(stopType, store);
    const newFormData = R.mergeRight(newStop.formData, withDates);

    return R.assoc('formData', newFormData, newStop);
  }
  const newStop = createNewDODrop(stopOrder);
  const withDates = G.getBranchNewStopDateTimeDefaultValues(stopType, store);
  const newFormData = R.mergeRight(newStop.formData, withDates);

  return R.assoc('formData', newFormData, newStop);
};

const createNewStopWithContainers = (stopType: string, stopOrder: string, store: Object) => {
  const { branchDefaultItem } = store;

  if (R.equals(stopType, GC.EVENT_TYPE_PICKUP)) {
    const newStop = createNewDOPickup(stopOrder);
    const withDates = G.getBranchNewStopDateTimeDefaultValues(stopType, store);
    const newFormData = R.mergeRight(newStop.formData, withDates);
    const firstPickupContainerInternalId = P.$get('stops.1.formData.pickedUpContainers.0.containerInternalId', store);
    const itemInternalId = G.generateGuid();
    const item = {
      ...branchDefaultItem,
      itemInternalId,
      containerInternalId: firstPickupContainerInternalId,
    };
    const formDataWithItem = R.assoc('items', R.of(Array, item), newFormData);

    return R.assoc('formData', formDataWithItem, newStop);
  }

  const newStop = createNewDODrop(stopOrder);
  const withDates = G.getBranchNewStopDateTimeDefaultValues(stopType, store);
  const newFormData = R.mergeRight(newStop.formData, withDates);

  return R.assoc('formData', newFormData, newStop);
};

const pageTypeToInitialDataMap = {
  [PAGE_TYPE_CREATE_DO]: createDOData,
  [PAGE_TYPE_CLO_TEMPLATE]: templateData,
  [PAGE_TYPE_CREATE_FROM_EDI]: createFromEDIData,
  [PAGE_TYPE_CREATE_FROM_QUOTE]: createFromQuoteData,
  [PAGE_TYPE_CREATE_FROM_PUBLIC_CLO]: createFromPublicCloData,
  [PAGE_TYPE_CREATE_FROM_ORDER_QUOTE]: createFromOrderQuoteData,
};

const getInitialState = (pageType: string, sourceType: string = GC.CREATE_DO_SOURCE_TYPE_MANUAL) => {
  const pickup1 = createNewDOPickup(1);
  const drop1 = createNewDODrop(2);
  const initial = R.prop(pageType, pageTypeToInitialDataMap);
  const initData = R.mergeRight(staticData, initial);
  return R.mergeRight(
    initData,
    {
      sourceType,
      stops: {
        1: pickup1,
        2: drop1,
      },
    },
  );
};

const setValueToStore = (state: Object, { path, value }: Object) => (
  P.$set(path, value, state)
);

const setBranchBillTo = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const payTermsConfig = G.getConfigValueFromStore(GC.CLO_GENERAL_PAYMENT_TERMS, state.branchConfigs);
  const payTerms = G.ifElse(
    G.isNotNilAndNotEmpty(payTermsConfig),
    payTermsConfig,
    '',
  );

  const billTo = {
    ...data,
    'defaultBillTo': true,
    [GC.FIELD_PAYMENT_TERM]: payTerms,
  };

  return P.$set('billTo', billTo, state);
};

const setBranchShipFrom = (state: Object, data: Object) => (
  P.$set(
    'stops.1.formData',
    R.mergeRight(
      R.mergeRight(R.pathOr({}, ['stops', 1, 'formData'], state), data),
      G.getBranchPickupDateTimeDefaultValues(state.branchConfigs),
    ),
    state,
  )
);

const setBranchShipTo = (state: Object, data: Object) => (
  P.$set(
    'stops.2.formData',
    R.mergeRight(
      R.mergeRight(R.pathOr({}, ['stops', 2, 'formData'], state), data),
      G.getBranchDropDateTimeDefaultValues(state.branchConfigs),
    ),
    state,
  )
);

const setBranchDefaultItem = (state: Object, data: Object) => {
  const itemInternalId = G.generateGuid();
  const itemWithInternalId = R.assoc(GC.FIELD_ITEM_INTERNAL_ID, itemInternalId, data);
  const configs = G.getItemFromWindow('amousNewDoBranchConfigs');
  const useContainers = G.getConfigValueFromStore(GC.CLO_GENERAL_USE_CONTAINERS, configs);

  if (G.isTrue(useContainers)) return P.$set('branchDefaultItem', data, state);

  return P.$all(
    P.$set('stops.1.formData.items', R.of(Array, itemWithInternalId)),
    P.$set('stops.2.formData.items', R.of(Array, itemInternalId)),
    state,
  );
};

const setActiveLeftTab = (state: Object, data: Object) => (
  P.$set('leftActiveTad', data, state)
);

const setActiveRightTab = (state: Object, data: Object) => (
  P.$set('rightActiveTad', data, state)
);

const setNumberOfLoads = (state: Object, data: string) => (
  P.$set('numberOfLoads', data, state)
);

const setTelCreationMode = (state: Object, data: Object) => (
  P.$set('telCreationMode', data, state)
);

const toggleHotOrder = (state: Object) => (
  P.$toggle('hotOrder', state)
);

const stopApptsFields = [
  GC.FIELD_LOAD_FCFS,
  GC.FIELD_STOP_NUMBER,
  GC.FIELD_LOAD_EVENT_LATE_DATE,
  GC.FIELD_LOAD_EVENT_EARLY_DATE,
  GC.FIELD_LOAD_APPOINTMENT_DATE,
  GC.FIELD_LOAD_APPOINTMENT_NUMBER,
  GC.FIELD_LOAD_APPOINTMENT_REQUIRED,
  GC.FIELD_LOAD_APPOINTMENT_LATE_TIME,
  GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME,
];

const stopDatesApptsFields = [
  GC.FIELD_LOAD_FCFS,
  GC.FIELD_STOP_NUMBER,
  GC.FIELD_LOAD_EVENT_LATE_TIME,
  GC.FIELD_LOAD_EVENT_LATE_DATE,
  GC.FIELD_LOAD_EVENT_EARLY_TIME,
  GC.FIELD_LOAD_EVENT_EARLY_DATE,
  GC.FIELD_LOAD_APPOINTMENT_DATE,
  GC.FIELD_LOAD_APPOINTMENT_NUMBER,
  GC.FIELD_LOAD_APPOINTMENT_REQUIRED,
  GC.FIELD_LOAD_APPOINTMENT_LATE_TIME,
  GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME,
];


const getContainersFromEvent = (event: Object) => {
  const droppedContainers = R.pathOr([], [GC.FIELD_STOP_DROPPED_CONTAINERS], event);
  const pickedUpContainers = R.pathOr([], [GC.FIELD_STOP_PICKED_UP_CONTAINERS], event);

  return { droppedContainers, pickedUpContainers };
};

const mapDataEvent = (event: Object, withOutData: boolean = false) => {
  const {
    items,
    location,
    eventType,
    internalId,
    references,
    stopNumber,
    cloEventIndex,
    droppedTrailerGuids,
    pickedUpTrailerGuids,
  } = event;

  const datesAppts = R.pick(stopDatesApptsFields, event);
  const containers = getContainersFromEvent(event);
  const createStopFunc = G.ifElse(
    G.isEventTypePickup(eventType),
    createNewDOPickup,
    createNewDODrop,
  );
  const itemsToUse = G.ifElse(
    G.isEventTypePickup(eventType),
    items,
    R.map((item: Object) => R.prop(GC.FIELD_ITEM_INTERNAL_ID, item), items),
  );
  const init = createStopFunc(cloEventIndex);

  if (withOutData) {
    const newFormData = {
      ...init.formData,
      ...G.resetLocationTypeFromDropdownOption(location),
      ...containers,
      items: itemsToUse,
      droppedTrailerGuids,
      pickedUpTrailerGuids,
    };

    return R.assoc('formData', newFormData, init);
  }

  const newFormData = {
    ...init.formData,
    ...G.resetLocationTypeFromDropdownOption(location),
    ...datesAppts,
    ...containers,
    internalId,
    references,
    stopNumber,
    items: itemsToUse,
    droppedTrailerGuids,
    pickedUpTrailerGuids,
  };

  return R.assoc('formData', newFormData, init);
};

const mapDataEvents = (events: Array, withOutData: boolean = false) => R.compose(
  R.indexBy(R.prop(GC.FIELD_ORDER)),
  R.map((item: Object) => mapDataEvent(item, withOutData)),
)(events);

const mapEdiEvents = R.compose(
  R.indexBy(R.prop(GC.FIELD_ORDER)),
  G.mapIndexed((event: Object, index: number) => R.assoc(GC.FIELD_ORDER, R.inc(index), mapDataEvent(event))),
);

const mapLoadStopsEditDataEvent = ({ event, loadType }: Object) => {
  const { items, location, eventType, references, droppedTrailerGuids, pickedUpTrailerGuids } = event;

  const appts = R.pick(stopApptsFields, event);
  const containers = getContainersFromEvent(event);
  const itemsToUse = G.ifElse(
    G.isEventTypePickup(eventType),
    items,
    R.map((item: Object) => R.prop(GC.FIELD_ITEM_INTERNAL_ID, item), items),
  );
  const init = createExistedStopData({ event, loadType });

  const newFormData = {
    ...init.formData,
    ...G.resetLocationTypeFromDropdownOption(location),
    ...appts,
    ...containers,
    references,
    items: itemsToUse,
    droppedTrailerGuids,
    pickedUpTrailerGuids,
  };

  return R.assoc('formData', newFormData, init);
};

const mapLoadStopsEditDataEvents = ({ events, loadType }: Object) => R.compose(
  R.indexBy(R.prop(GC.FIELD_ORDER)),
  R.map((event: Object) => mapLoadStopsEditDataEvent({ event, loadType })),
)(events);

let quotePickupItems = [];

const getQuotePickupItems = (items: any) => {
  if (G.isNilOrEmpty(items)) return [];

  const itemsToUse = R.map(
    (item: Object) => R.mergeRight(getNewItemFields(), item),
    items,
  );

  quotePickupItems = itemsToUse;

  return itemsToUse;
};

const getQuoteDropItems = () => {
  if (G.isNilOrEmpty(quotePickupItems)) return [];

  const items = R.map((item: Object) => R.prop(GC.FIELD_ITEM_INTERNAL_ID, item), quotePickupItems);
  quotePickupItems = [];

  return items;
};

// TODO: check locationName from sequence here
const mapQuoteDataEvent = (event: Object, index: number, items: any) => {
  const { location } = event;

  const eventEarlyDate = R.path([GC.FIELD_LOAD_EVENT_EARLY_DATE], event);
  const containers = getContainersFromEvent(event);
  const createStopFunc = G.ifElse(
    G.isZero(index),
    createNewDOPickup,
    createNewDODrop,
  );
  const itemsToUse = G.ifElse(
    G.isZero(index),
    getQuotePickupItems(items),
    getQuoteDropItems(),
  );
  const init = createStopFunc(R.inc(index));

  const newFormData = {
    ...init.formData,
    ...location,
    ...containers,
    items: itemsToUse,
    [GC.FIELD_LOAD_EVENT_EARLY_DATE]: eventEarlyDate,
  };

  return R.assoc('formData', newFormData, init);
};

const mapQuoteDataEvents = (events: Array, items: any) => R.compose(
  R.indexBy(R.prop(GC.FIELD_ORDER)),
  G.mapIndexed((event: Object, index: number) => mapQuoteDataEvent(event, index, items)),
)(events);

const getBillToFromData = (state: Object, dataBillTo: Object, branchConfigs: Object) => {
  const { billTo } = state;

  const payTerms = G.getConfigValueFromStore(GC.CLO_GENERAL_PAYMENT_TERMS, branchConfigs);
  const billToWithTerms = R.assoc(GC.FIELD_PAYMENT_TERM, payTerms, billTo);

  if (G.isObject(dataBillTo)) {
    return G.resetLocationTypeFromDropdownOption(
      R.mergeRight(
        billToWithTerms,
        R.assoc('defaultBillTo', true, dataBillTo),
      ));
  }

  return G.resetLocationTypeFromDropdownOption(billToWithTerms);
};

const mapRateInitCharges = (rate: Object) => {
  const charges = G.getChargesFromObject(rate);

  if (G.isNilOrEmpty(charges)) return rate;

  const chargesToUse = R.map(
    (item: Object) => R.assoc(GC.FIELD_ID, R.or(G.getGuidFromObject(item), G.genShortId()), item),
    charges,
  );

  return R.assoc(GC.FIELD_CHARGES, chargesToUse, rate);
};

const getRateFromData = (rate: Object, branchConfigs: Object, isValid: boolean, equipmentServices: Object) => {
  if (G.isNilOrEmpty(rate)) return null;

  const defaultEquipment = G.getConfigValueFromStore(GC.CLO_GENERAL_DEFAULT_EQUIPMENT);
  const currency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, branchConfigs);
  const mode = G.getConfigValueFromStore(GC.CLO_GENERAL_RATE_TRANSPORTATION_MODE, branchConfigs);

  const equipment = R.pathOr(
    defaultEquipment,
    [GC.FIELD_LOAD_EQUIPMENT, GC.FIELD_DROPDOWN_OPTION_GUID],
    equipmentServices,
  );

  const services = R.map(R.prop(GC.FIELD_DROPDOWN_OPTION_GUID), R.propOr([], GC.FIELD_SERVICES, equipmentServices));

  const init = {
    services,
    equipment,
    [GC.FIELD_MODE]: mode,
    isValid: R.or(isValid, false),
    [GC.FIELD_CURRENCY]: currency,
  };

  return R.mergeRight(init, mapRateInitCharges(rate));
};

const getTelCreationMode = (branchConfigs: Object) => {
  const telCreationMode = G.getConfigValueFromStore(GC.CLO_GENERAL_TEL_CREATION_MODE, branchConfigs);

  return G.ifElse(
    G.isNotNilAndNotEmpty(telCreationMode),
    telCreationMode,
    GC.TEL_CREATION_MODE_SINGLE_TEL,
  );
};

const getBranchConfigsWithEdiSuccess = (state: Object, data: Object) => {
  const { ediData, branchGuid, branchConfigs } = data;

  const {
    clo: {
      rate,
      billTo,
      events,
      references,
      specialInstructions,
      internalInstructions,
      primaryReferenceValue,
    },
  } = ediData;

  const { branchList, branchRefTypes } = state;

  const branchInfo = R.find(R.propEq(branchGuid, GC.FIELD_GUID), branchList);

  const branchInfoMapped = {
    value: R.prop(GC.FIELD_GUID, branchInfo),
    label: R.prop(GC.FIELD_NAME, branchInfo),
  };
  const divisionGuid = getDivisionGuidByBranchConfigs(branchConfigs, branchInfoMapped);
  const telCreationMode = getTelCreationMode(branchConfigs);
  const primaryReference = getPrimaryReference(branchConfigs, branchRefTypes);
  const defaultOrderType = G.getConfigValueFromStore(GC.CLO_GENERAL_DEFAULT_ORDER_TYPE, branchConfigs);

  G.setItemToWindow('amousNewDoBranchConfigs', branchConfigs);

  return P.$all(
    P.$set('ediData', ediData),
    P.$set('divisionGuid', divisionGuid),
    P.$set('orderType', defaultOrderType),
    P.$set('stops', mapEdiEvents(events)),
    P.$set('branchInfo', branchInfoMapped),
    P.$set('branchConfigs', branchConfigs),
    P.$set('telCreationMode', telCreationMode),
    P.$set('primaryReference', primaryReference),
    P.$set('pageType', PAGE_TYPE_CREATE_FROM_EDI),
    P.$set('referenceFormData.references', references),
    P.$set('customerLoadNumber', primaryReferenceValue),
    P.$set('rate', getRateFromData(rate, branchConfigs)),
    P.$set('rateBackup', getRateFromData(rate, branchConfigs)),
    P.$set('billTo', getBillToFromData(state, billTo, branchConfigs)),
    P.$set('referenceFormData.specialInstructions', specialInstructions),
    P.$set('referenceFormData.internalInstructions', internalInstructions),
    state,
  );
};

const getQuotePricing = (data: Object) => {
  const { ratePrices, contactInfo, selectedPriceGuid } = data;

  if (G.isNilOrEmpty(ratePrices)) return null;

  const rate = R.find((field: Object) => R.pathEq(selectedPriceGuid, ['price', GC.FIELD_GUID], field), ratePrices);

  if (G.isNilOrEmpty(rate)) return null;

  const { price, charges } = rate;

  const currency = R.path([GC.FIELD_CURRENCY], price);
  const transportationMode = R.path([GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], price);
  const serviceType = R.compose(
    R.path([GC.FIELD_DROPDOWN_OPTION_GUID]),
    R.path(['serviceTypes', 0]),
  )(price);
  const init = {
    ...contactInfo,
    charges,
    isValid: false,
    [GC.FIELD_CURRENCY]: currency,
    [GC.FIELD_MODE]: transportationMode,
    [GC.FIELD_SERVICE_TYPE]: serviceType,
  };

  return R.mergeRight(init, rate);
};

const getBranchConfigsWithQuoteSuccess = (state: Object, data: Object) => {
  const { quoteData } = data;
  const { items, lastEvent, firstEvent } = quoteData;

  const rate = getQuotePricing(quoteData);

  return P.$all(
    P.$set('rate', rate),
    P.$set('rateBackup', rate),
    P.$set('quoteData', quoteData),
    P.$set('pageType', PAGE_TYPE_CREATE_FROM_QUOTE),
    P.$set('stops', mapQuoteDataEvents([firstEvent, lastEvent], items)),
    P.$set('referenceFormData.specialInstructions', R.pathOr(null, [GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS], quoteData)),
    state,
  );
};

const getReferenceFormDataFromTemplate = (template: Object) => ({
  isValid: true,
  [GC.FIELD_LOAD_SERVICES]: R.map(
    (item: Object) => R.prop(GC.FIELD_DROPDOWN_OPTION_GUID, item),
    R.pathOr([], [GC.FIELD_LOAD_SERVICES], template),
  ),
  [GC.FIELD_LOAD_REFERENCES]: R.map(
    (item: Object) => R.pick([GC.FIELD_VALUE, GC.FIELD_REFERENCE_TYPE_GUID], item),
    R.pathOr([], [GC.FIELD_LOAD_REFERENCES], template),
  ),
  [GC.FIELD_FAST_LOAD]: R.path([GC.FIELD_FAST_LOAD], template),
  [GC.FIELD_PINNED_NOTE]: R.path([GC.FIELD_PINNED_NOTE], template),
  [GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS]: R.path([GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS], template),
  [GC.FIELD_LOAD_INTERNAL_INSTRUCTIONS]: R.path([GC.FIELD_LOAD_INTERNAL_INSTRUCTIONS], template),
  [GC.FIELD_LOAD_EQUIPMENT]: R.path([GC.FIELD_LOAD_EQUIPMENT, GC.FIELD_DROPDOWN_OPTION_GUID], template),
});

const getBranchConfigsWithTemplateSuccess = (state: Object, data: Object) => {
  const { branchGuid, templateData, branchConfigs } = data;

  const { rate, billTo, events, services, equipment, orderType, divisionGuid } = templateData;

  const { branchList, sharedAccessorialsList } = state;

  const branchInfo = R.find(R.propEq(branchGuid, GC.FIELD_GUID), branchList);

  const branchInfoMapped = {
    value: R.prop(GC.FIELD_GUID, branchInfo),
    label: R.prop(GC.FIELD_NAME, branchInfo),
  };


  const orderTypeToUse = G.getDropdownOptionGuidFromObject(orderType);

  G.setItemToWindow('amousNewDoBranchConfigs', branchConfigs);

  return P.$all(
    P.$set('templateData', templateData),
    P.$set('divisionGuid', divisionGuid),
    P.$set('stops', mapDataEvents(events)),
    P.$set('branchInfo', branchInfoMapped),
    P.$set('branchConfigs', branchConfigs),
    P.$set('billTo', getBillToFromData(state, billTo, branchConfigs)),
    P.$set('referenceFormData', getReferenceFormDataFromTemplate(templateData)),
    P.$set('telCreationMode', R.path([GC.FIELD_TEL_CREATION_MODE], templateData)),
    P.$set('numberOfLoads', R.pathOr(1, [GC.FIELD_NUMBER_OF_LOADS], templateData)),
    P.$set('orderType', R.or(orderTypeToUse, G.getPropFromObject('orderType', state))),
    P.$set(
      'rate',
      getRateWithChargesFromData(rate, branchConfigs, false, sharedAccessorialsList, { services, equipment }),
    ),
    P.$set(
      'rateBackup',
      getRateWithChargesFromData(rate, branchConfigs, false, sharedAccessorialsList, { services, equipment }),
    ),
    state,
  );
};

const getBranchConfigsWithLoadStopsSuccess = (state: Object, data: Object) => {
  const { events, fromPage, loadGuid, loadType, branchGuid, branchConfigs } = data;

  G.setItemToWindow('amousNewDoBranchConfigs', branchConfigs);

  return P.$all(
    P.$set('stopsData', data),
    P.$set('leftActiveTad', 1),
    P.$set('fromPage', fromPage),
    P.$set('loadGuid', loadGuid),
    P.$set('loadType', loadType),
    P.$set('branchInfo.value', branchGuid),
    P.$set('branchConfigs', branchConfigs),
    P.$set('pageType', PAGE_TYPE_LOAD_STOPS_EDIT),
    P.$set('stops', mapLoadStopsEditDataEvents({ events, loadType })),
    state,
  );
};

const mapEventsWithTimes = (events: Array) => (
  R.map((item: Object) => G.setEventEarlyLateTimeFromEarlyLateDate2(item),
  events,
));

const copyTemplateDataToStore = (state: Object, data: Object) => {
  const { branchConfigs, sharedAccessorialsList } = state;

  const { rate, billTo, events, services, equipment, orderType } = data;

  const eventsToUse = mapEventsWithTimes(events);

  const orderTypeToUse = G.getDropdownOptionGuidFromObject(orderType);

  return P.$all(
    P.$set('templateData', data),
    P.$set('stops', mapDataEvents(eventsToUse)),
    P.$set('billTo', getBillToFromData(state, billTo, branchConfigs)),
    P.$set('referenceFormData', getReferenceFormDataFromTemplate(data)),
    P.$set('telCreationMode', R.path([GC.FIELD_TEL_CREATION_MODE], data)),
    P.$set('numberOfLoads', R.pathOr(1, [GC.FIELD_NUMBER_OF_LOADS], data)),
    P.$set('orderType', R.or(orderTypeToUse, G.getPropFromObject('orderType', state))),
    P.$set(
      'rate',
      getRateWithChargesFromData(rate, branchConfigs, false, sharedAccessorialsList, { services, equipment }),
    ),
    P.$set(
      'rateBackup',
      getRateWithChargesFromData(rate, branchConfigs, false, sharedAccessorialsList, { services, equipment }),
    ),
    state,
  );
};

const setItemOrContainer = (useContainers: any, branchConfigs: Object, state: Object) => {
  if (G.isTrue(useContainers)) {
    const withContainers = R.assocPath(
      [GC.FIELD_STOP_PICKED_UP_CONTAINERS, 0],
      getNewContainerFields(),
      state,
    );

    return R.assoc('items', [], withContainers);
  }

  return R.assocPath(
    ['items', 0],
    getNewItemFields(branchConfigs),
    state,
  );
};

const setDefaultLocationTypeToValues = (values: Object, locationType: any) => {
  if (G.isNilOrEmpty(locationType)) return values;

  return R.assoc(GC.FIELD_LOCATION_TYPE, locationType, values);
};

const getBranchConfigsSuccess = (state: Object, data: Object) => {
  if (R.includes(
    state.pageType,
    [
      PAGE_TYPE_CLO_TEMPLATE,
      PAGE_TYPE_CREATE_FROM_EDI,
      PAGE_TYPE_CREATE_FROM_PUBLIC_CLO,
      PAGE_TYPE_CREATE_FROM_ORDER_QUOTE,
    ],
  )) return state;

  const { branchRefTypes } = state;

  const { branchConfigs } = data;

  const telCreationMode = getTelCreationMode(branchConfigs);

  const primaryReference = getPrimaryReference(branchConfigs, branchRefTypes);

  const useContainers = G.getConfigValueFromStore(GC.CLO_GENERAL_USE_CONTAINERS, branchConfigs);
  const autoCreateContainerItem = G.getConfigValueFromStore(GC.CLO_GENERAL_AUTO_CREATE_CONTAINER_ITEM, branchConfigs);
  const defaultReferences = G.getConfigValueFromStore(GC.CLO_GENERAL_DEFAULT_REFERENCES, branchConfigs);
  const defaultEquipment = G.getConfigValueFromStore(GC.CLO_GENERAL_DEFAULT_EQUIPMENT, branchConfigs);
  const defaultOrderType = G.getConfigValueFromStore(GC.CLO_GENERAL_DEFAULT_ORDER_TYPE, branchConfigs);
  const defaultPickupLocationType = G.getConfigValueFromStore(GC.CLO_DEFAULT_PICKUP_LOCATION_TYPE, branchConfigs);
  const defaultSpecialInstruction = G.getConfigValueFromStore(GC.CLO_DEFAULT_SPECIAL_INSTRUCTION, branchConfigs);
  const defaultDropLocationType = G.getConfigValueFromStore(GC.CLO_DEFAULT_DROP_LOCATION_TYPE, branchConfigs);

  const withPickupDates = R.mergeRight(
    R.pathOr({}, ['stops', 1, 'formData'], state),
    setDefaultLocationTypeToValues(G.getBranchPickupDateTimeDefaultValues(branchConfigs), defaultPickupLocationType),
  );

  const withDefaultItemOrContainer = setItemOrContainer(useContainers, branchConfigs, withPickupDates);

  const withDropDates = R.mergeRight(
    R.pathOr({}, ['stops', 2, 'formData'], state),
    setDefaultLocationTypeToValues(G.getBranchDropDateTimeDefaultValues(branchConfigs), defaultDropLocationType),
  );

  G.setItemToWindow('amousNewDoBranchConfigs', branchConfigs);

  if (G.isNotNilAndNotEmpty(defaultReferences)) {
    const references = R.map((item: string) => ({
      [GC.FIELD_VALUE]: null,
      [GC.FIELD_REFERENCE_TYPE_GUID]: item,
    }), defaultReferences);

    return P.$all(
      P.$set('orderType', defaultOrderType),
      P.$set('useContainers', useContainers),
      P.$set('branchConfigs', branchConfigs),
      P.$set('stops.2.formData', withDropDates),
      P.$set('telCreationMode', telCreationMode),
      P.$set('primaryReference', primaryReference),
      P.$set('referenceFormData.references', references),
      P.$set('stops.1.formData', withDefaultItemOrContainer),
      P.$set('autoCreateContainerItem', autoCreateContainerItem),
      P.$set('billTo', getBillToFromData(state, null, branchConfigs)),
      P.$set('referenceFormData.equipment', R.or(defaultEquipment, null)),
      P.$set('referenceFormData.specialInstructions', R.or(defaultSpecialInstruction, null)),
      state,
    );
  }

  return P.$all(
    P.$set('orderType', defaultOrderType),
    P.$set('useContainers', useContainers),
    P.$set('branchConfigs', branchConfigs),
    P.$set('stops.2.formData', withDropDates),
    P.$set('telCreationMode', telCreationMode),
    P.$set('primaryReference', primaryReference),
    P.$set('stops.1.formData', withDefaultItemOrContainer),
    P.$set('autoCreateContainerItem', autoCreateContainerItem),
    P.$set('billTo', getBillToFromData(state, null, branchConfigs)),
    P.$set('referenceFormData.equipment', R.or(defaultEquipment, null)),
    P.$set('referenceFormData.specialInstructions', R.or(defaultSpecialInstruction, null)),
    state,
  );
};

const getBranchConfigsOnQuoteSuccess = (state: Object, data: Object) => {
  const { branchConfigs } = data;
  const telCreationMode = getTelCreationMode(branchConfigs);
  const defaultReferences = G.getConfigValueFromStore(GC.CLO_GENERAL_DEFAULT_REFERENCES, branchConfigs);
  G.setItemToWindow('amousNewDoBranchConfigs', branchConfigs);
  if (G.isNotNilAndNotEmpty(defaultReferences)) {
    const references = R.map((item: string) => ({
      [GC.FIELD_VALUE]: null,
      [GC.FIELD_REFERENCE_TYPE_GUID]: item,
    }), defaultReferences);
    return P.$all(
      P.$set('branchConfigs', branchConfigs),
      P.$set('telCreationMode', telCreationMode),
      P.$set('referenceFormData.references', references),
      state,
    );
  }
  return P.$all(
    P.$set('branchConfigs', branchConfigs),
    P.$set('telCreationMode', telCreationMode),
    state,
  );
};

const getReferenceFormDataFromDuplicateDO = (data: Object, branchConfigs: Object) => {
  let references = [];
  const defaultEquipment = G.getConfigValueFromStore(GC.CLO_GENERAL_DEFAULT_EQUIPMENT, branchConfigs);
  const defaultReferences = G.getConfigValueFromStore(GC.CLO_GENERAL_DEFAULT_REFERENCES, branchConfigs);

  if (G.isNotNilAndNotEmpty(defaultReferences)) {
    references = R.map((item: string) => ({
      [GC.FIELD_VALUE]: null,
      [GC.FIELD_REFERENCE_TYPE_GUID]: item,
    }), defaultReferences);
  }

  const equipment = R.or(
    R.path([GC.FIELD_LOAD_EQUIPMENT, GC.FIELD_DROPDOWN_OPTION_GUID], data),
    R.or(defaultEquipment, null),
  );
  const services = R.map(
    (item: Object) => R.prop(GC.FIELD_DROPDOWN_OPTION_GUID, item),
    R.pathOr([], [GC.FIELD_LOAD_SERVICES], data),
  );

  return ({
    isValid: true,
    [GC.FIELD_LOAD_SERVICES]: services,
    [GC.FIELD_LOAD_EQUIPMENT]: equipment,
    [GC.FIELD_LOAD_REFERENCES]: references,
    [GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS]: R.path([GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS], data),
    [GC.FIELD_LOAD_INTERNAL_INSTRUCTIONS]: R.path([GC.FIELD_LOAD_INTERNAL_INSTRUCTIONS], data),
  });
};

const getBranchConfigsOnDuplicateDOSuccess = (state: Object, data: Object) => {
  const { branchGuid, branchConfigs, duplicateDOData } = data;

  const { rate, billTo, events } = duplicateDOData;

  const { branchList } = state;

  const branchInfo = R.find(R.propEq(branchGuid, GC.FIELD_GUID), branchList);

  const branchInfoMapped = {
    value: R.prop(GC.FIELD_GUID, branchInfo),
    label: R.prop(GC.FIELD_NAME, branchInfo),
  };

  G.setItemToWindow('amousNewDoBranchConfigs', branchConfigs);

  return P.$all(
    P.$set('duplicateDO', duplicateDOData),
    P.$set('branchInfo', branchInfoMapped),
    P.$set('branchConfigs', branchConfigs),
    P.$set('stops', mapDataEvents(events, true)),
    P.$set('sourceType', GC.CREATE_DO_SOURCE_TYPE_COPY),
    P.$set('rate', getRateFromData(rate, branchConfigs, true)),
    P.$set('telCreationMode', getTelCreationMode(branchConfigs)),
    P.$set('rateBackup', getRateFromData(rate, branchConfigs, true)),
    P.$set('billTo', getBillToFromData(state, billTo, branchConfigs)),
    P.$set('numberOfLoads', R.pathOr(1, [GC.FIELD_NUMBER_OF_LOADS], duplicateDOData)),
    P.$set('referenceFormData', getReferenceFormDataFromDuplicateDO(duplicateDOData, branchConfigs)),
    state,
  );
};

const setEdiFileToStore = (state: Object, data: Object) => (
  P.$set('ediFile', data, state)
);

const setRatePreviewFiles = (state: Object, data: Array) => {
  if (G.isNilOrEmpty(data)) {
    G.setItemToWindow('amousCreateDoRateConfirmationFiles', data);

    return P.$set('ratePreviewFiles', data, state);
  }

  const currentFiles = R.or(G.getItemFromWindow('amousCreateDoRateConfirmationFiles'), []);
  const files = R.concat(currentFiles, data);

  const filesWithPreview = R.map((file: Object) => R.assoc('preview', URL.createObjectURL(file), file), files);

  G.setItemToWindow('amousCreateDoRateConfirmationFiles', files);

  return P.$set('ratePreviewFiles', filesWithPreview, state);
};

const setFormDataToStop = (state: Object, data: Object) => P.$all(
  P.$set(`stops.${data.stopOrder}.isValid`, data.isValid),
  P.$set(`stops.${data.stopOrder}.formData`, data.formData),
  state,
);

const setFormDataToStore = (state: Object, data: Object) => {
  const { dataName, formData } = data;

  if (R.equals(dataName, 'rate')) {
    return P.$all(
      P.$set('rate', formData),
      P.$set('rateBackup', formData),
      state,
    );
  }

  return P.$set(dataName, formData, state);
};

const setCustomerRateToStore = (state: Object, data: Object) => (
  P.$set('rate', data, state)
);

const setCustomerRateChargesToStore = (state: Object, data: Object) => {
  const { rate, telDriverRate, telCarrierRate } = state;

  if (G.isNilOrEmpty(rate)) {
    const fields = R.map(R.prop('fieldName'), allCustomerRateFields);
    const rate = R.assoc(
      GC.FIELD_CHARGES,
      data,
      R.pick(fields, R.or(telDriverRate, telCarrierRate)),
    );

    return P.$set('rate', rate, state);
  }

  return P.$all(
    P.$set('rate.charges', data),
    P.$set('rateBackup.charges', data),
    state,
  );
};

export const setCarrierRateToStore = (state: Object, data: Object) => {
  const { stops } = state;

  const { estimatedDeliveryEarlyDate, estimatedDeliveryLateDate } = data;

  const dropEarlyDate = P.$get('2.formData.eventEarlyDate', stops);
  const dropLateDate = P.$get('2.formData.eventLateDate', stops);

  const dropEventEarlyDate = R.or(estimatedDeliveryEarlyDate, dropEarlyDate);
  const dropEventLateDate = R.or(estimatedDeliveryLateDate, dropLateDate);

  const newStops = P.$all(
    P.$set('2.formData.eventEarlyDate', dropEventEarlyDate),
    P.$set('2.formData.eventLateDate', dropEventLateDate),
    stops,
  );

  return P.$all(
    P.$set('stops', newStops),
    P.$set('telCarrierRate', data, state),
  );
};

const setDriverRateToStore = (state: Object, data: Object) => (
  P.$set('telDriverRate', data, state)
);

const addNewStopToStore = (state: Object, stopType: Object) => {
  const {
    stops,
    pageType,
    sourceType,
    useContainers,
    branchDefaultItem,
    addedNewFirstDrop,
    addedNewFirstPickup,
    autoCreateContainerItem } = state;

  if (R.and(
    G.isAllTrue(
      useContainers,
      autoCreateContainerItem,
      isPageCreateDO(pageType),
      G.isEventTypePickup(stopType),
      isSourceTypeEdiOrManual(sourceType),
      G.isNotNilAndNotEmpty(branchDefaultItem),
    ),
    G.isAllFalse(
      addedNewFirstDrop,
      addedNewFirstPickup,
    ),
  )) {
    const stopOrder = 2;
    const stop = createNewStopWithContainers(stopType, stopOrder, state);
    const stopsWithAdded = R.insert(1, stop, R.values(stops));
    const newStops = R.compose(
      R.indexBy(R.prop(GC.FIELD_ORDER)),
      G.mapIndexed((stop: Object, index: number) => R.assoc(GC.FIELD_ORDER, R.inc(index), stop)),
    )(stopsWithAdded);
    const firstPickupContainerInternalId = P.$get('1.formData.pickedUpContainers.0.containerInternalId', newStops);
    const secondPickupItemInternalId = P.$get('2.formData.items.0.itemInternalId', newStops);
    const secondPickupEventEarlyDate = P.$get('2.formData.eventEarlyDate', newStops);
    const secondPickupEventLateDate = P.$get('2.formData.eventLateDate', newStops);
    const dropEventEarlyDate = P.$get('3.formData.eventEarlyDate', newStops);
    const dropEventLateDate = P.$get('3.formData.eventLateDate', newStops);
    const newStops2 = P.$all(
      P.$set('3.formData.items', R.of(Array, secondPickupItemInternalId)),
      P.$set('3.formData.droppedContainers', R.of(Array, firstPickupContainerInternalId)),
      P.$set('3.formData.eventEarlyDate', secondPickupEventEarlyDate),
      P.$set('3.formData.eventLateDate', secondPickupEventLateDate),
      P.$set('2.formData.eventEarlyDate', dropEventEarlyDate),
      P.$set('2.formData.eventLateDate', dropEventLateDate),
      newStops,
    );

    return P.$all(
      P.$set('stops', newStops2),
      P.$toggle('addedNewFirstPickup'),
      state,
    );
  }

  if (R.and(
    G.isAllTrue(
      useContainers,
      autoCreateContainerItem,
      isPageCreateDO(pageType),
      G.isEventTypeDrop(stopType),
      isSourceTypeEdiOrManual(sourceType),
      G.isNotNilAndNotEmpty(branchDefaultItem),
    ),
    G.isAllFalse(
      addedNewFirstDrop,
      addedNewFirstPickup,
    ),
  )) {
    const stopOrder = 3;
    const stop = createNewStopWithContainers(stopType, stopOrder, state);
    const stopsWithAdded = R.append(stop, R.values(stops));
    const newStops = R.compose(
      R.indexBy(R.prop(GC.FIELD_ORDER)),
      G.mapIndexed((stop: Object, index: number) => R.assoc(GC.FIELD_ORDER, R.inc(index), stop)),
    )(stopsWithAdded);
    const firstPickupContainerInternalId = P.$get('1.formData.pickedUpContainers.0.containerInternalId', newStops);
    const itemInternalId = G.generateGuid();
    const item = {
      ...branchDefaultItem,
      itemInternalId,
      containerInternalId: firstPickupContainerInternalId,
    };
    const newStops2 = P.$set('1.formData.items', R.of(Array, item), newStops);
    const newStops3 = P.$all(
      P.$set('2.formData.items', R.of(Array, itemInternalId)),
      P.$set('3.formData.droppedContainers', R.of(Array, firstPickupContainerInternalId)),
      newStops2,
    );

    return P.$all(
      P.$set('stops', newStops3),
      P.$toggle('addedNewFirstDrop'),
      state,
    );
  }

  const stopOrder = R.inc(R.length(R.values(state.stops)));
  const stop = createNewStop(stopType, stopOrder, state);

  return P.$set(`stops.${stopOrder}`, stop, state);
};

const removeStopFromStore = (state: Object, stopOrder: Object) => {
  const stops = R.compose(
    R.indexBy(R.prop(GC.FIELD_ORDER)),
    G.mapIndexed((item: Object, index: number) => R.assoc(GC.FIELD_ORDER, R.inc(index), item)),
    R.reject((item: Object) => R.equals(R.prop(GC.FIELD_ORDER, item), stopOrder)),
    R.sortBy(R.prop(GC.FIELD_ORDER)),
    R.values(),
  )(state.stops);
  return P.$all(
    P.$set('stops', stops),
    P.$set('leftActiveTad', 1),
    state,
  );
};

const removeItemFromStore = (state: Object, itemInternalId: Object) => {
  const stops = R.compose(
    R.indexBy(R.prop(GC.FIELD_ORDER)),
    R.map((stop: Object) => {
      const { formData: { items }, eventType } = stop;
      if (G.isEventTypeDrop(eventType)) {
        const newItems = R.without(itemInternalId, items);
        return R.assocPath(['formData', GC.FIELD_LOAD_ITEMS], newItems, stop);
      }
      return stop;
    }),
    R.values(),
  )(state.stops);

  return P.$set('stops', stops, state);
};

const setReorderedStops = (state: Object, data: Object) => (
  P.$set('stops', data, state)
);

const recalculateLoadDistancesSuccess = (state: Object, data: Object) => {
  const { distance, stopPoints, mappedStops } = data;

  if (G.isNotNilAndNotEmpty(distance)) {
    const rate = R.mergeRight(state.rateBackup, distance);

    const rateWithValid = R.assoc('isValid', isValidCustomerRateForm(rate), rate);

    const stopPointsStrings = getStopPointsLatLonStringsArray(stopPoints);

    return P.$all(
      P.$set('stops', mappedStops),
      P.$set('rate', rateWithValid),
      P.$set('rateBackup', rateWithValid),
      P.$set('stopPointsStrings', stopPointsStrings),
      state,
    );
  }

  return P.$set('stops', mappedStops, state);
};

const getBranchListSuccess = (state: Object, data: Array) => (
  P.$set('branchList', data, state)
);

const getBranchStylingSuccess = (state: Object, data: Array) => (
  P.$set('styling', data, state)
);

const getPrimaryRefSequenceSuccess = (state: Object, data: string) => P.$all(
  P.$set('primaryRefSequence', data),
  P.$set('customerLoadNumber', createCustomerLoadNumber({
    sequence: data,
    configs: state.branchConfigs,
    divisionPrefix: state.divisionPrefix,
  })),
  state,
);

const setCustomerLoadNumberByBranchConfigs = (state: Object, { configs }: Object) => {
  const divisionPrefix = G.getConfigValueFromStore(
    GC.CLO_PRIMARY_REFERENCE_DIVISION_PREFIX,
    configs,
  );

  return P.$all(
    P.$set('divisionPrefix', divisionPrefix),
    P.$set('customerLoadNumber', createCustomerLoadNumber({
      divisionPrefix,
      configs: state.branchConfigs,
      sequence: state.primaryRefSequence,
    })),
    state,
  );
};

const getAllAvBranchRefTypesSuccess = (state: Object, { scopeName, data }: Object) => (
  P.$set(`branchRefTypes.${scopeName}`, data, state)
);

const setCustomerLoadNumber = (state: Object, value: string) => (
  P.$set('customerLoadNumber', value, state)
);

const setCustomerReferenceValue = (state: Object, value: string) => (
  P.$set('customerReferenceValue', value, state)
);

const setDivisionGuid = (state: Object, value: string) => (
  P.$set('divisionGuid', value, state)
);

const setPageTypeToStore = (state: Object, value: string) => (
  P.$set('pageType', value, state)
);

const saveAndDuplicateSuccess = (state: Object) => (
  P.$all(
    P.$set('ignoreWarnings', false),
    P.$set('validationWarnings', null),
    P.$set('customerReferenceValue', ''),
    P.$set('referenceFormData.references', []),
    P.$set('saveAndDuplicateQty', R.inc(state.saveAndDuplicateQty)),
    state,
  )
);

const setBranchInfo = (state: Object, data: object) => G.ifElse(
  R.isNil(data),
  P.$set('branchInfo', GC.EMPTY_OPTION_OBJECT, state),
  P.$set('branchInfo', data, state),
);

const setPrimaryReference = (state: Object, data: object) => (
  G.ifElse(
    R.isNil(data),
    P.$set('primaryReference', GC.EMPTY_OPTION_OBJECT, state),
    P.$set('primaryReference', data, state),
  )
);

const setValidationErrors = (state: Object, data: Object) => {
  if (G.isNotNilAndNotEmpty(data)) {
    return P.$set('validationErrors', data, state);
  }
  return state;
};

const setValidationErrorsAndWarnings = (state: Object, { errors, warnings }: Object) => (
  P.$all(
    P.$set('validationErrors', errors),
    P.$set('validationWarnings', warnings),
    state,
  )
);

const removeValidationErrors = (state: Object) => (
  P.$set('validationErrors', null, state)
);

const removeValidationWarnings = (state: Object) => (
  P.$set('validationWarnings', null, state)
);

const setIgnoreWarnings = (state: Object, value: boolean = false) => (
  P.$all(
    P.$set('ignoreWarnings', value),
    P.$set('validationWarnings', G.ifElse(G.isTrue(value), null, state.validationWarnings)),
    state,
  )
);

const setInitialStateToStore = () => (
  getInitialState(PAGE_TYPE_CREATE_DO)
);

const cleanNewDOStore = (state: Object, data: string) => {
  const guid = R.path([GC.FIELD_GUID], data);
  const pageType = R.pathOr(state.pageType, ['pageType'], data);
  const sourceType = G.getPropFromObject(GC.FIELD_SOURCE_TYPE, data);
  let customerInfo = GC.EMPTY_OPTION_OBJECT;
  if (G.isNotNil(guid)) {
    customerInfo = {
      value: guid,
      label: data[GC.FIELD_BRANCH_NAME],
    };
  }
  const branchList = P.$get('branchList', state);

  return P.$all(
    P.$set('branchList', branchList),
    P.$set('branchInfo', customerInfo),
    getInitialState(pageType, sourceType),
  );
};

// public clo
const mapPublicCloEvents = (events: Array, allItems: Object) => R.compose(
  R.indexBy(R.prop(GC.FIELD_ORDER)),
  G.mapIndexed((event: Object, index: number) => {
    const {
      itemIds,
      location,
      eventType,
      references,
      containers,
      cloEventIndex,
      cloDistanceToNextStop } = event;

    const eventContainers = R.or(containers, []);
    const appts = R.pick(stopApptsFields, event);
    const createStopFunc = G.ifElse(
      G.isEventTypePickup(eventType),
      createNewDOPickup,
      createNewDODrop,
    );
    const itemsToUse = G.ifElse(G.isEventTypePickup(eventType), R.values(R.pick(itemIds, allItems)), itemIds);
    const containersToUse = G.ifElse(
      G.isEventTypePickup(eventType),
      eventContainers,
      R.map((item: Object) => R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, item), eventContainers),
    );
    const init = createStopFunc(cloEventIndex);
    const newFormData = {
      ...init.formData,
      ...event,
      ...G.resetLocationTypeFromDropdownOption(location),
      ...appts,
      references,
      order: index,
      items: itemsToUse,
      containers: containersToUse,
    };

    return {
      ...init,
      cloDistanceToNextStop,
      formData: newFormData,
    };
  }),
)(events);

const getBranchConfigsWithPublicCloDataSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(R.path(['publicCloData', GC.FIELD_CLO], data))) return state;

  const { branchList, branchRefTypes } = state;
  const { branchGuid, branchConfigs, publicCloData } = data;
  const { clo: {
    rate,
    items,
    events,
    billTo,
    services,
    equipment,
    references,
    divisionGuid,
    specialInstructions,
    primaryReferenceValue } } = publicCloData;

  const branchInfo = R.find(R.propEq(branchGuid, GC.FIELD_GUID), branchList);
  const branchInfoMapped = {
    value: R.prop(GC.FIELD_GUID, branchInfo),
    label: R.prop(GC.FIELD_NAME, branchInfo),
  };
  const telCreationMode = getTelCreationMode(branchConfigs);
  const primaryReference = getPrimaryReference(branchConfigs, branchRefTypes);
  const filteredReferences = R.filter(({ primary }: Object) => G.isFalse(primary), references);
  const getPrimaryReferenceValue = R.compose(
    R.pathOr(primaryReferenceValue, [0, GC.FIELD_VALUE]),
    R.filter(R.prop(GC.FIELD_PRIMARY)),
  )(references);
  const allItems = R.indexBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID), items);
  const stops = mapPublicCloEvents(events, allItems);
  G.setItemToWindow('amousNewDoBranchConfigs', branchConfigs);

  return P.$all(
    P.$set('stops', stops),
    P.$set('divisionGuid', divisionGuid),
    P.$set('publicCloData', publicCloData),
    P.$set('branchInfo', branchInfoMapped),
    P.$set('branchConfigs', branchConfigs),
    P.$set('telCreationMode', telCreationMode),
    P.$set('primaryReference', primaryReference),
    P.$set('pageType', PAGE_TYPE_CREATE_FROM_PUBLIC_CLO),
    P.$set('customerLoadNumber', getPrimaryReferenceValue),
    P.$set('referenceFormData.references', filteredReferences),
    P.$set('billTo', getBillToFromData(state, billTo, branchConfigs)),
    P.$set('referenceFormData.specialInstructions', specialInstructions),
    P.$set('rate', getRateFromData(rate, branchConfigs, { equipment, services })),
    P.$set('rateBackup', getRateFromData(rate, branchConfigs, { equipment, services })),
    P.$set('referenceFormData.equipment', G.getPropFromObject(GC.FIELD_DROPDOWN_OPTION_GUID, equipment)),
    P.$set('referenceFormData.services', R.map(R.prop(GC.FIELD_DROPDOWN_OPTION_GUID), R.or(services, []))),
    state,
  );
};

// order quote
const getBranchConfigsWithOrderQuoteDataSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(R.path(['orderQuoteData'], data))) return state;

  const { branchList, branchRefTypes } = state;
  const { branchGuid, branchConfigs, orderQuoteData } = data;
  const {
    rate,
    guid,
    items,
    events,
    services,
    equipment,
    references,
    divisionGuid,
    telFleetRate,
    telCarrierRate,
    orderQuoteNumber,
    specialInstructions,
  } = orderQuoteData;

  const branchInfo = R.find(R.propEq(branchGuid, GC.FIELD_GUID), branchList);
  const branchInfoMapped = {
    value: R.prop(GC.FIELD_GUID, branchInfo),
    label: R.prop(GC.FIELD_NAME, branchInfo),
  };
  const telCreationMode = getTelCreationMode(branchConfigs);
  const primaryReference = getPrimaryReference(branchConfigs, branchRefTypes);
  const filteredReferences = R.filter(({ primary }: Object) => G.isFalse(primary), R.or(references, []));
  const allItems = R.indexBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID), R.or(items, []));
  G.setItemToWindow('amousNewDoBranchConfigs', branchConfigs);

  return P.$all(
    P.$set('divisionGuid', divisionGuid),
    P.$set('telDriverRate', telFleetRate),
    P.$set('branchInfo', branchInfoMapped),
    P.$set('branchConfigs', branchConfigs),
    P.$set('telCarrierRate', telCarrierRate),
    P.$set('telCreationMode', telCreationMode),
    P.$set('primaryReference', primaryReference),
    P.$set('stops', mapPublicCloEvents(events, allItems)),
    P.$set('pageType', PAGE_TYPE_CREATE_FROM_ORDER_QUOTE),
    P.$set('referenceFormData.references', filteredReferences),
    P.$set('orderQuoteData', { orderQuoteNumber, orderQuoteGuid: guid }),
    P.$set('referenceFormData.specialInstructions', specialInstructions),
    P.$set('rate', getRateFromData(rate, branchConfigs, { equipment, services })),
    P.$set('rateBackup', getRateFromData(rate, branchConfigs, { equipment, services })),
    P.$set('referenceFormData.equipment', G.getPropFromObject(GC.FIELD_DROPDOWN_OPTION_GUID, equipment)),
    P.$set('referenceFormData.services', R.map(R.prop(GC.FIELD_DROPDOWN_OPTION_GUID), R.or(services, []))),
    state,
  );
};

const getBranchSharedAccessorialListSuccess = (state: Object, data: Array) => (
  P.$set('sharedAccessorialsList', data, state)
);

// transportation mode grouping
const getTransportationModeGroupingListSuccess = (state: Object, data: Object) => (
  P.$set('transportationModeGroupingList', R.indexBy(R.prop(GC.FIELD_TRANSPORTATION_MODE_GUID), R.or(data, [])), state)
);

// ai recognize
let recognizeEventPickupItems = [];

const getRecognizeEventPickupItems = (items: any, itemIds: any) => {
  if (R.or(G.isNilOrEmpty(items), G.isNilOrEmpty(itemIds))) return [];

  const itemsToUse = R.compose(
    R.map((item: Object) => R.mergeRight(getNewItemFields(), item)),
    R.filter(({ itemInternalId }: Object) => R.includes(itemInternalId, itemIds)),
  )(items);

  recognizeEventPickupItems = itemsToUse;

  return itemsToUse;
};

const getRecognizeEventDropItems = (itemIds: any) => {
  if (R.or(G.isNilOrEmpty(recognizeEventPickupItems), G.isNilOrEmpty(itemIds))) return [];

  const items = R.compose(
    R.map((item: Object) => R.prop(GC.FIELD_ITEM_INTERNAL_ID, item)),
    R.filter(({ itemInternalId }: Object) => R.includes(itemInternalId, itemIds)),
  )(recognizeEventPickupItems);

  recognizeEventPickupItems = [];

  return items;
};

const mapRecognizeEvent = (event: Object, index: number, items: any) => {
  const { itemIds, comments, location, eventType, eventLateDate, eventEarlyDate } = event;

  const contacts = R.compose(
    R.map((contact: Object) => {
      const phone = contact?.phone;

      if (G.isNotNilAndNotEmpty(phone)) {

        if (isValidPhoneNumber(phone)) return contact;

        const parsed = parsePhoneNumber(phone, 'US');

        const number = parsed?.number;

        if (isValidPhoneNumber(number)) return R.assoc(GC.FIELD_PHONE, number, contact);
      }

      return contact;
    }),
    R.pathOr([], [GC.FIELD_CONTACTS]),
  )(location);

  const createStopFunc = G.ifElse(
    G.isEventTypePickup(eventType),
    createNewDOPickup,
    createNewDODrop,
  );

  const itemsToUse =
    G.isEventTypePickup(eventType) ?
    getRecognizeEventPickupItems(items, itemIds) :
    getRecognizeEventDropItems(itemIds);

  const init = createStopFunc(R.inc(index));

  // TODO: check with API if all contacts data empty
  const newFormData = {
    ...init.formData,
    ...location,
    contacts,
    comments,
    eventLateDate,
    eventEarlyDate,
    items: itemsToUse,
  };

  return R.assoc('formData', newFormData, init);
};

export const mapRecognizeStopsData = (events: Array, items: any) => R.compose(
  R.indexBy(R.prop(GC.FIELD_ORDER)),
  G.mapIndexed((event: Object, index: number) => mapRecognizeEvent(event, index, items)),
)(events);

const mapRecognizeRateInitCharges = (rate: Object) => {
  const charges = G.getChargesFromObject(rate);

  if (G.isNilOrEmpty(charges)) return rate;

  const chargesToUse = R.map(
    (item: Object) => {
      const id = R.or(G.getGuidFromObject(item), G.genShortId());
      // TODO: check default storedValue with API
      const storedValue = R.or(G.getPropFromObject(GC.FIELD_STORED_VALUE, item), G.genShortId());

      return {
        ...item,
        [GC.FIELD_ID]: id,
        [GC.FIELD_STORED_VALUE]: storedValue,
      };
    },
    charges,
  );

  return R.assoc(GC.FIELD_CHARGES, chargesToUse, rate);
};

export const getRateFromRecognizeData = (rate: Object, branchConfigs: Object) => {
  if (G.isNilOrEmpty(rate)) return null;

  const currency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, branchConfigs);
  const mode = G.getConfigValueFromStore(GC.CLO_GENERAL_RATE_TRANSPORTATION_MODE, branchConfigs);

  const init = {
    isValid: false,
    [GC.FIELD_MODE]: mode,
    [GC.FIELD_CURRENCY]: currency,
  };

  return R.mergeDeepRight(init, mapRecognizeRateInitCharges(rate));
};

export const getBillToFromRecognizeData = (state: Object, dataBillTo: Object) => {
  const { billTo } = state;

  if (G.isTrue(G.getPropFromObject('defaultBillTo', billTo))) return billTo;

  let billToUse = G.assign(dataBillTo);

  if (G.isObject(dataBillTo)) {
    const { emails } = dataBillTo;

    const init = R.pick([GC.FIELD_COUNTRY, GC.FIELD_PAYMENT_TERM], billTo);

    // TODO: with validation all cases
    if (R.propEq('USA', GC.FIELD_COUNTRY, billToUse)) {
      billToUse = { ...billToUse, [GC.FIELD_COUNTRY]: 'United States' };
    }

    const finalBillToUse = R.assoc('emails', (emails || []).filter(Boolean), billToUse);

    return G.mergeWithoutNullUndefinedOrEmptyString(init, finalBillToUse);
  }

  return billTo;
};

const aiRecognizeSuccess = (state: Object, data: Object) => {
  try {
    const { branchConfigs } = state;

    const {
      rate,
      items,
      notes,
      billTo,
      events,
      specialInstructions,
    } = data;

    const stops = mapRecognizeStopsData(events, items);

    const rateToUse = getRateFromRecognizeData(rate, branchConfigs);

    const billToUse = getBillToFromRecognizeData(state, billTo);

    return P.$all(
      P.$set('aiRecognize.data', data),
      //
      P.$set('rate', rateToUse),
      P.$set('rateBackup', rateToUse),
      //
      P.$set('stops', stops),
      P.$set('billTo', billToUse),
      //
      P.$set('referenceFormData.pinnedNote', notes),
      P.$set('referenceFormData.specialInstructions', specialInstructions),
      state,
    );
  } catch (error) {
    return state;
  }
};

const fetchStopLatLonSuccess = (state: Object, payload: Object) => {
  const { data, stopIndex } = payload;

  const { latitude, longitude } = data;

  if (G.isAllTrue(
    G.isNotNilAndNotEmpty(latitude),
    G.isNotNilAndNotEmpty(longitude),
    G.isNotNilAndNotEmpty(stopIndex),
  )) {
    const currentFormData = P.$get(`stops.${stopIndex}.formData`, state);

    const formData = R.mergeDeepRight(currentFormData, data);

    return P.$set(`stops.${stopIndex}.formData`, formData, state);
  }

  return state;
};
// ai recognize

export default createReducer({
  [A.setBranchInfo]: setBranchInfo,
  [A.toggleHotOrder]: toggleHotOrder,
  [A.setDivisionGuid]: setDivisionGuid,
  [A.setBranchShipTo]: setBranchShipTo,
  [A.cleanNewDOStore]: cleanNewDOStore,
  [A.setBranchBillTo]: setBranchBillTo,
  [A.setValueToStore]: setValueToStore,
  [A.setNumberOfLoads]: setNumberOfLoads,
  [A.setActiveLeftTab]: setActiveLeftTab,
  [A.setEdiFileToStore]: setEdiFileToStore,
  [A.setActiveRightTab]: setActiveRightTab,
  [A.setBranchShipFrom]: setBranchShipFrom,
  [A.setReorderedStops]: setReorderedStops,
  [A.setFormDataToStop]: setFormDataToStop,
  [A.addNewStopToStore]: addNewStopToStore,
  [A.setIgnoreWarnings]: setIgnoreWarnings,
  [A.setPageTypeToStore]: setPageTypeToStore,
  [A.setFormDataToStore]: setFormDataToStore,
  [A.setTelCreationMode]: setTelCreationMode,
  [A.setRatePreviewFiles]: setRatePreviewFiles,
  [A.removeItemFromStore]: removeItemFromStore,
  [A.setValidationErrors]: setValidationErrors,
  [A.removeStopFromStore]: removeStopFromStore,
  [A.setPrimaryReference]: setPrimaryReference,
  [A.setBranchDefaultItem]: setBranchDefaultItem,
  [A.getBranchListSuccess]: getBranchListSuccess,
  [A.setDriverRateToStore]: setDriverRateToStore,
  [A.setCarrierRateToStore]: setCarrierRateToStore,
  [A.setCustomerLoadNumber]: setCustomerLoadNumber,
  [A.setInitialStateToStore]: setInitialStateToStore,
  [A.removeValidationErrors]: removeValidationErrors,
  [A.setCustomerRateToStore]: setCustomerRateToStore,
  [A.saveAndDuplicateSuccess]: saveAndDuplicateSuccess,
  [A.getBranchStylingSuccess]: getBranchStylingSuccess,
  [A.getBranchConfigsSuccess]: getBranchConfigsSuccess,
  [A.copyTemplateDataToStore]: copyTemplateDataToStore,
  [A.removeValidationWarnings]: removeValidationWarnings,
  [A.setCustomerReferenceValue]: setCustomerReferenceValue,
  [A.getPrimaryRefSequenceSuccess]: getPrimaryRefSequenceSuccess,
  [A.setCustomerRateChargesToStore]: setCustomerRateChargesToStore,
  [A.getAllAvBranchRefTypesSuccess]: getAllAvBranchRefTypesSuccess,
  [A.getBranchConfigsOnQuoteSuccess]: getBranchConfigsOnQuoteSuccess,
  [A.getBranchConfigsWithEdiSuccess]: getBranchConfigsWithEdiSuccess,
  [A.setValidationErrorsAndWarnings]: setValidationErrorsAndWarnings,
  [A.recalculateLoadDistancesSuccess]: recalculateLoadDistancesSuccess,
  [A.getBranchConfigsWithQuoteSuccess]: getBranchConfigsWithQuoteSuccess,
  [A.getBranchConfigsWithTemplateSuccess]: getBranchConfigsWithTemplateSuccess,
  [A.getBranchConfigsOnDuplicateDOSuccess]: getBranchConfigsOnDuplicateDOSuccess,
  [A.getBranchConfigsWithLoadStopsSuccess]: getBranchConfigsWithLoadStopsSuccess,
  [A.getBranchSharedAccessorialListSuccess]: getBranchSharedAccessorialListSuccess,
  // branch configs
  [getBranchConfigsByNamesSuccess]: setCustomerLoadNumberByBranchConfigs,
  // public clo
  [A.getBranchConfigsWithPublicCloDataSuccess]: getBranchConfigsWithPublicCloDataSuccess,
  // order quote
  [A.getBranchConfigsWithOrderQuoteDataSuccess]: getBranchConfigsWithOrderQuoteDataSuccess,
  // transportation mode grouping
  [A.getTransportationModeGroupingListSuccess]: getTransportationModeGroupingListSuccess,
  // ai recognize
  [A.aiRecognizeSuccess]: aiRecognizeSuccess,
  [A.fetchStopLatLonSuccess]: fetchStopLatLonSuccess,
}, getInitialState(PAGE_TYPE_CREATE_DO));
