import * as R from 'ramda';
import React, { useState } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// mui
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
// components
import { ActionsElement, ActionsElement2 } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

const enhance = compose(
  withHandlers({
    handleEditPayrollInvoices: ({ closeFixedPopup, openUpdatePayrollInvoices }: Object) => () => {
      openUpdatePayrollInvoices();
      closeFixedPopup();
    },
    handleGetPayrollXML: ({ closeFixedPopup, handleGetPayrollXMLRequest }: Object) => () => {
      handleGetPayrollXMLRequest();
      closeFixedPopup();
    },
    handleGetPayrollNewXML: ({ closeFixedPopup, handleGetPayrollNewXMLRequest }: Object) => () => {
      handleGetPayrollNewXMLRequest();
      closeFixedPopup();
    },
    handleGetXmlFileByTruck: ({ closeFixedPopup, getXmlFileByTruckRequest }: Object) => () => {
      getXmlFileByTruckRequest();
      closeFixedPopup();
    },
    handleSendToQuickBook: ({ closeFixedPopup, handleSendToQuickBookRequest }: Object) => (desktop: any) => {
      handleSendToQuickBookRequest(desktop);
      closeFixedPopup();
    },
    handleQBIIFImport: ({ payroll, closeFixedPopup, qbIIFImportRequest }: Object) => () => {
      qbIIFImportRequest(R.of(Array, payroll.guid));
      closeFixedPopup();
    },
    handleChaseCSVExport: ({ payroll, closeFixedPopup, chaseCSVExportRequest }: Object) => () => {
      chaseCSVExportRequest(R.of(Array, G.getGuidFromObject(payroll)));
      closeFixedPopup();
    },
    handlePrint: ({ closeFixedPopup, handlePrintPayroll }: Object) => () => {
      handlePrintPayroll();
      closeFixedPopup();
    },
    handleGetDriverRatesXML: ({ payroll, closeFixedPopup, getDriverRatesXmlFileRequest }: Object) => () => {
      getDriverRatesXmlFileRequest(G.getGuidFromObject(payroll));
      closeFixedPopup();
    },
    handleCustomExport: ({ payroll, closeFixedPopup, handleCustomExport }: Object) => () => {
      closeFixedPopup();
      handleCustomExport(G.getGuidFromObject(payroll));
    },
    handleSendPayrollsToSageIntacct: ({ closeFixedPopup, sendPayrollsToSageIntacct }: Object) => () => {
      closeFixedPopup();
      sendPayrollsToSageIntacct();
    },
    handleShowStatement: ({ closeFixedPopup, handleShowStatement }: Object) => () => {
      closeFixedPopup();
      handleShowStatement();
    },
  }),
  pure,
);

const getOptions = (props: Object) => {
  const {
    isVendor,
    handlePrint,
    handleShowAudit,
    handleQBIIFImport,
    handleCustomExport,
    handleGetPayrollXML,
    handleShowStatement,
    handleChaseCSVExport,
    handleSendToQuickBook,
    hasCustomExportAction,
    handleGetPayrollNewXML,
    handleGetDriverRatesXML,
    handleGetXmlFileByTruck,
    handleEditPayrollInvoices,
    handleSendPayrollsToSageIntacct,
  } = props;

  const iconStyles = [iconColor, 15, 17];

  const readPermission = G.ifElse(isVendor, PC.FLEET_VENDOR_PAYROLL_READ, PC.DRIVER_PAYROLL_READ);
  const writePermission = G.ifElse(isVendor, PC.FLEET_VENDOR_PAYROLL_WRITE, PC.DRIVER_PAYROLL_WRITE);

  const overwritePermission = G.ifElse(
    isVendor,
    PC.FLEET_VENDOR_PAYROLL_OVERWRITE_EXECUTE,
    PC.DRIVER_PAYROLL_OVERWRITE_EXECUTE,
  );

  const permissions = G.ifElse(
    G.isTrue(R.path(['payroll', 'completed'], props)),
    overwritePermission,
    [writePermission, overwritePermission],
  );

  const options = [
    {
      permissions,
      frontIcon: I.pencil(iconColor),
      action: handleEditPayrollInvoices,
      text: G.getWindowLocale('actions:update-invoices', 'Update Invoices'),
    },
    {
      action: handleGetPayrollXML,
      frontIcon: I.downloadDocument(...iconStyles),
      text: G.getWindowLocale('actions:get-xml-file', 'Get XML File'),
    },
    {
      action: handlePrint,
      frontIcon: I.printer(iconColor),
      text: G.getWindowLocale('actions:print', 'Print'),
    },
    {
      action: handleShowStatement,
      frontIcon: I.documents(iconColor, 15, 17),
      text: G.getWindowLocale('actions:show-statement', 'Show Statement'),
    },
    {
      action: handleSendToQuickBook,
      frontIcon: I.quickbook(iconColor),
      text: G.getWindowLocale('actions:export-to-QB', 'Export to QuickBooks'),
    },
    {
      frontIcon: I.quickbook(iconColor),
      action: () => handleSendToQuickBook(true),
      text: G.getWindowLocale('actions:export-to-QB-desktop', 'Export to QuickBooks Desktop'),
    },
    {
      action: handleQBIIFImport,
      frontIcon: I.quickbook(iconColor, 16, 16),
      permissions: [readPermission, writePermission, overwritePermission],
      text: G.getWindowLocale('actions:qb-iif-export', 'QuickBooks IIF Export'),
    },
    {
      action: handleChaseCSVExport,
      frontIcon: I.downloadDocument(...iconStyles),
      permissions: [readPermission, writePermission, overwritePermission],
      text: G.getWindowLocale('actions:chase-csv-export', 'Chase CSV Export'),
    },
    {
      action: handleShowAudit,
      text: G.getWindowLocale('titles:audit', 'Audit'),
      frontIcon: I.renderAuditHistoryIcon(iconColor, 15, 17),
    },
  ];

  if (isVendor) {
    const driverRatesXmlOption = {
      action: handleGetDriverRatesXML,
      frontIcon: I.downloadDocument(...iconStyles),
      text: G.getWindowLocale('actions:get-driver-rates-xml-file', 'Get Driver Rates XML File'),
    };

    const newXMLFileOption = {
      action: handleGetPayrollNewXML,
      frontIcon: I.downloadDocument(...iconStyles),
      text: G.getWindowLocale('actions:get-new-xml-file', 'Get New XML File'),
    };

    const sageIntacctOption = {
      permissions,
      frontIcon: I.gear(iconColor),
      action: handleSendPayrollsToSageIntacct,
      text: G.getWindowLocale('actions:send-to-sage-intacct', 'Send To Sage Intacct'),
    };

    const xmlByTruckOption = {
      permissions,
      action: handleGetXmlFileByTruck,
      frontIcon: I.downloadDocument(...iconStyles),
      text: G.getWindowLocale('actions:get-xml-file-grouped-by-truck', 'Get XML File Grouped by Truck'),
    };

    return R.compose(
      R.insertAll(2, [newXMLFileOption, xmlByTruckOption, driverRatesXmlOption]),
      R.insert(5, sageIntacctOption),
    )(options);
  }

  if (G.isTrue(hasCustomExportAction)) {
    const customExportActionOption = {
      action: handleCustomExport,
      frontIcon: I.downloadDocument(...iconStyles),
      text: G.getWindowLocale('actions:custom-export', 'Custom Export'),
      permissions: [readPermission, writePermission, overwritePermission],
    };

    return R.append(customExportActionOption, options);
  }

  return options;
};

const getHeaderOptions = (props: Object) => {
  const {
    isVendor,
    handleShowAudit,
    handlePrintPayroll,
    getPayrollXMLRequest,
    handleSendToQuickBook,
    handleSendPayrollsToSageIntacct,
  } = props;

  const iconStyles = [iconColor, 15, 17];

  const writePermission = G.ifElse(isVendor, PC.FLEET_VENDOR_PAYROLL_WRITE, PC.DRIVER_PAYROLL_WRITE);

  const overwritePermission = G.ifElse(
    isVendor,
    PC.FLEET_VENDOR_PAYROLL_OVERWRITE_EXECUTE,
    PC.DRIVER_PAYROLL_OVERWRITE_EXECUTE,
  );

  const permissions = G.ifElse(
    G.isTrue(R.path(['payroll', 'completed'], props)),
    overwritePermission,
    [writePermission, overwritePermission],
  );

  const options = [
    {
      iconMl: 10,
      withTitle: true,
      action: handlePrintPayroll,
      frontIcon: I.printer(iconColor),
      text: G.getWindowLocale('actions:print-payroll', 'Print Payroll'),
    },
    {
      action: getPayrollXMLRequest,
      frontIcon: I.downloadTemplate(...iconStyles),
      text: G.getWindowLocale('actions:get-xml-file', 'Get XML File'),
    },
    {
      action: handleSendToQuickBook,
      frontIcon: I.quickbook(iconColor),
      text: G.getWindowLocale('actions:export-to-QB', 'Export to QuickBooks'),
    },
    {
      action: handleShowAudit,
      text: G.getWindowLocale('titles:audit', 'Audit'),
      frontIcon: I.renderAuditHistoryIcon(iconColor, 15, 17),
    },
  ];

  if (isVendor) {
    const sageIntacctOption = {
      permissions,
      frontIcon: I.gear(iconColor),
      action: handleSendPayrollsToSageIntacct,
      text: G.getWindowLocale('actions:send-to-sage-intacct', 'Send To Sage Intacct'),
    };

    return R.insert(2, sageIntacctOption, R.remove(1, 1, options));
  }

  return options;
};

const XmlMenu = (props: Object) => {
  const {
    getPayrollXMLRequest,
    getPayrollNewXMLRequest,
    getXmlFileByTruckRequest,
    getDriverRatesXmlFileRequest,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: Object) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGetXml = () => {
    getPayrollXMLRequest();

    setAnchorEl(null);
  };

  const handleGetNewXml = () => {
    getPayrollNewXMLRequest();

    setAnchorEl(null);
  };

  const handleGetDriverRatesXml = () => {
    getDriverRatesXmlFileRequest();

    setAnchorEl(null);
  };

  const handleGetXmlFileByTruck = () => {
    getXmlFileByTruckRequest();

    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        id='xml-button'
        aria-haspopup='true'
        onClick={handleClick}
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'xml-menu' : undefined}
      >
        {G.getWindowLocale('titles:get-xml', 'Get Xml')}
      </Button>
      <Menu
        open={open}
        id='xml-menu'
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'xml-button',
        }}
      >
        <MenuItem onClick={handleGetXml}>
          {G.getWindowLocale('actions:get-xml-file', 'Get XML File')}
        </MenuItem>
        <MenuItem onClick={handleGetNewXml}>
          {G.getWindowLocale('actions:get-new-xml-file', 'Get New XML File')}
        </MenuItem>
        <MenuItem onClick={handleGetXmlFileByTruck}>
          {G.getWindowLocale('actions:get-xml-file-grouped-by-truck', 'Get XML File Grouped by Truck')}
        </MenuItem>
        <MenuItem onClick={handleGetDriverRatesXml}>
          {G.getWindowLocale('actions:get-driver-rates-xml-file', 'Get Driver Rates XML File')}
        </MenuItem>
      </Menu>
    </Box>
  );
};

const PayrollHeaderActions = (props: Object) => {
  const { isVendor } = props;

  if (isVendor) {
    return (
      <Flex>
        <ActionsElement2 options={getHeaderOptions(props)} />
        <XmlMenu {...props} />
      </Flex>
    );
  }

  return <ActionsElement2 options={getHeaderOptions(props)} />;
};

const PayrollActions = (props: Object) => <ActionsElement options={getOptions(props)} />;

export default enhance(PayrollActions);

export {
  PayrollActions,
  PayrollHeaderActions,
};
