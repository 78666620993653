import * as R from 'ramda';
import React from 'react';
import { OuterClick } from 'react-outer-click';
import {
  compose,
  withState,
  withHandlers,
} from 'react-recompose';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
import BranchTreeComponent from '../../branch/components/branch-tree';
import AutodialButton from '../../load-board/components/autodial-button';
import ShareWithDivisions from '../../branch/components/share-with-divisions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// utilities
import routesMap from '../../../utilities/routes';
// ui
import { Box, Flex } from '../../../ui';
// feature navbar-menu
import BranchTitle from './branch-title';
import Logo from './left-navigation/logo-box';
import UserBox from './left-navigation/user-box';
import CreateNew from './left-navigation/create-new';
import SidebarComponent from './expand-panel/sidebar';
import PinnedItems from './left-navigation/pinned-items';
import LoadBoardIcon from './left-navigation/load-board';
import NavbarButton from './left-navigation/navbar-button';
import { PanelToggle, BottomPart, MenuWrapper } from './ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState('isSidebarOpen', 'setSidebarStatus', false),
  withHandlers({
    handleShareWithDivisions: (props: Object) => (guid: string) => {
      const { openModal } = props;

      const modal = {
        p: '0px',
        component: (
          <ShareWithDivisions branchGuid={guid} />
        ),
        options: {
          width: 500,
          height: 'auto',
          minWidth: 'fit-content',
          title: G.getWindowLocale('actions:share-with-divisions', 'Share With Divisions'),
        },
      };

      openModal(modal);
    },
  }),
  withHandlers({
    handleDispatchBoard: ({ clickOnItemAction }: Object) => () => {
      clickOnItemAction(GC.MENU_ACTION_ROUTE_BETA);
    },
    toggleNavbar: ({ setSidebarStatus }: Object) => () => setSidebarStatus((prev: boolean) => R.not(prev)),
    handleClickNavItem: ({ clickOnItemAction, setSidebarStatus }: Object) => (action: string) => {
      setSidebarStatus(false);
      clickOnItemAction(action);
    },
    handleOpenEntTree: (props: Object) => () => {
      const { openModal, closeModal, switchBranchRequest, handleShareWithDivisions } = props;

      const modal = {
        p: '0px',
        component: (
          <BranchTreeComponent
            width={500}
            allowPin={true}
            cursor='pointer'
            allowActions={true}
            closeAction={closeModal}
            handleShareWithDivisions={handleShareWithDivisions}
            onClickBranch={(guid: string) => {
              closeModal();
              switchBranchRequest(guid);
            }}
          />
        ),
        options: {
          width: 500,
          height: 'auto',
          minWidth: 'fit-content',
        },
      };

      openModal(modal);
    },
    handleOpenLoadBoardsSideBar: ({ setExpandedContainerOptions }: Object) => () => {
      if (R.equals(routesMap.externalloadboard, G.getCurrentPathname())) return;

      setExpandedContainerOptions({ opened: true, componentType: GC.PAGE_LOAD_BOARDS });
    },
    handleSwitchBranch: ({ switchBranchRequest }: Object) => (guid: string) => switchBranchRequest(guid),
  }),
);

const RegularMenu = (props: Object) => {
  const {
    styling,
    photoUrl,
    openModal,
    rootBranch,
    mainStyling,
    toggleNavbar,
    currentBranch,
    isSidebarOpen,
    currentMenuItem,
    setSidebarStatus,
    sendLogOutRequest,
    handleOpenEntTree,
    handleSwitchBranch,
    handleClickNavItem,
    loadBoardShipments,
    currentUserSettings,
    handleDispatchBoard,
    authorizedLoadBoards,
    unreadNotificationsCount,
    handleOpenLoadBoardsSideBar,
  } = props;

  const iconsColor = R.pathOr(G.getTheme('colors.light.mainLight'), ['textColor'], styling);

  const hideMenu = R.includes(G.getWindowLocationPathname(), [GC.ROUTE_PATH_USER_TERMS_AND_CONDITIONS]);

  const sidebarProps = {
    styling,
    openModal,
    iconsColor,
    mainStyling,
    currentBranch,
    isSidebarOpen,
    handleOpenEntTree,
    handleClickNavItem,
    handleSwitchBranch,
    branches: rootBranch,
    unreadNotificationsCount,
  };

  if (hideMenu) return null;

  return (
    <OuterClick onOuterClick={() => setSidebarStatus(false)}>
      <Box>
        <MenuWrapper bg={styling.backgroundColor}>
          <Flex
            gap='8px'
            alignItems='center'
            flexDirection='column'
            justifyContent='start'
          >
            <Logo
              styling={styling}
              iconsColor={iconsColor}
              toggleNavbar={toggleNavbar}
              unreadNotificationsCount={unreadNotificationsCount}
            />
            <CreateNew iconsColor={iconsColor} />
            <NavbarButton
              icon={I.routesLoads}
              iconsColor={iconsColor}
              onClick={handleDispatchBoard}
              active={R.equals(currentMenuItem, 'routeBeta')}
              has={[PC.CLO_READ, PC.CLO_WRITE, PC.TEL_READ, PC.TEL_WRITE]}
              text={G.getWindowLocale('menu:dispatch-board', 'Dispatch Board')}
            />
          </Flex>
          <PinnedItems handleClickNavItem={handleClickNavItem} currentMenuItem={currentMenuItem} />
          <BottomPart>
            {
              R.not(G.isCurrentBranchTypeCustomer()) &&
              <AuthWrapper has={[PC.EXTERNAL_LOAD_BOARD_EXECUTE]}>
                <AutodialButton iconOnly={true} styles={{margin: '10px'}} />
              </AuthWrapper>
            }
            {
              R.not(G.isCurrentBranchTypeCustomer()) &&
              <AuthWrapper has={[PC.EXTERNAL_LOAD_BOARD_EXECUTE]}>
                <LoadBoardIcon
                  styling={styling}
                  iconsColor={iconsColor}
                  count={R.length(loadBoardShipments)}
                  authorizedLoadBoards={authorizedLoadBoards}
                  handleOpenLoadBoardsSideBar={handleOpenLoadBoardsSideBar}
                />
              </AuthWrapper>
            }
            <UserBox photoUrl={photoUrl} iconsColor={iconsColor} currentUserSettings={currentUserSettings} />
            <NavbarButton
              icon={I.goOut}
              iconsColor={iconsColor}
              onClick={sendLogOutRequest}
              text={G.getWindowLocale('actions:logout', 'Logout')}
            />
            <PanelToggle title='Expand panel' onClick={toggleNavbar}>
              {I.navbarToggle(R.not(isSidebarOpen))}
            </PanelToggle>
          </BottomPart>
        </MenuWrapper>
        <SidebarComponent {...sidebarProps} />
        <BranchTitle iconsColor={iconsColor} handleOpenEntTree={handleOpenEntTree} />
      </Box>
    </OuterClick>
  );
};

export default enhance(RegularMenu);
