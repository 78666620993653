import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../components/text';
import { PopperWrapper } from '../../components/popper';
// features
import {
  eldOptions,
  tollChargeOptions,
  trailerIntegrationTypeOptions,
} from '../fleet-profile/settings/integration';
// forms
import { FieldsetComponent } from '../../forms';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import {
  COUNTRY_OPTIONS,
  FUEL_UOM_OPTIONS,
  BRANCH_TYPE_OPTIONS,
  DRIVER_TYPE_OPTIONS,
  LICENSE_CLASS_OPTIONS,
  ITEM_DIMENSIONS_OPTIONS,
  ITEM_TEMPERATURE_OPTIONS,
  LICENSE_RESTRICTION_OPTIONS,
  DEFAULT_DISTANCE_UOM_OPTIONS,
  DEFAULT_RATE_UNIT_GROUP_OPTIONS,
  DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  DEFAULT_CARRIER_RATE_TYPE_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
} from '../../helpers/options';
// ui
import { Box, ActionButton, ReactSelect } from '../../ui';
// feature new-import
import * as C from './constants';
import { requiredClass, TableHeadWrapper, TableHeaderCellWrapper } from './ui';
import { addTableRows, changeTableCell, addAdditionalFieldToTable } from './actions';
import {
  addRowsField,
  booleanOptions,
  additionalField,
  tollChargeSourceOptions,
  sequenceTemplateIdField,
} from './settings';
//////////////////////////////////////////////////

const addRowsEnhancer = compose(
  connect(null, { addTableRows }),
  withFormik({
    mapPropsToValues: () => ({ numberOfRows: null }),
    validationSchema: Yup.object().shape(
      { count: Yup.number().nullable(true).positive(G.getShouldBePositiveLocaleTxt()) },
    ),
    handleSubmit: ({ count }: Object, { props, resetForm }: Object) => {
      const { addTableRows } = props;

      addTableRows(count);
      resetForm();
    },
  }),
  pure,
);

export const AddTableRows = addRowsEnhancer((props: Object) => (
  <PopperWrapper
    type='importNumberOfRows'
    text={G.getWindowLocale('titles:add-rows', 'Add Rows')}
    popperContent={
      <Box p={15}>
        <form onSubmit={props.handleSubmit}>
          <FieldsetComponent {...G.getFormikProps(props)} fields={R.of(Array, addRowsField)} />
          <ActionButton m='5px' width={200}>
            {G.getWindowLocale('actions:add', 'Add')}
          </ActionButton>
        </form>
      </Box>
    }
  />
));

const sequenceEnhance = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({ [GC.FIELD_TEMPLATE_ID]: null }),
  validationSchema: Yup.object().shape(
    { [GC.FIELD_TEMPLATE_ID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()) },
  ),
  handleSubmit: ({ templateId }: Object, { props }: Object) => {
    const { importList, changeImportList } = props;

    const newList = G.mapIndexed((row: Object, rowIndex: number) => {

      if (R.has(GC.FIELD_TEMPLATE_ID, row)) {
        const value = `${templateId} ${R.inc(rowIndex)}`;
        const templateIdCell = R.mergeRight(
          G.getPropFromObject(GC.FIELD_TEMPLATE_ID, row),
          { value, label: value, className: 'success' },
        );

        return R.assoc(GC.FIELD_TEMPLATE_ID, templateIdCell, row);
      }

      return row;
    }, importList);

    changeImportList(newList);
  },
});

export const AddSequence = sequenceEnhance((props: Object) => (
  <PopperWrapper
    type='imports'
    text={G.getWindowLocale('titles:sequences', 'Sequences')}
    popperContent={
      <Box p={15}>
        <form onSubmit={props.handleSubmit}>
          <FieldsetComponent {...G.getFormikProps(props)} fields={R.of(Array, sequenceTemplateIdField)} />
          <ActionButton m='5px' width={200}>
            {G.getWindowLocale('actions:generate-template-id', 'Generate Template Id')}
          </ActionButton>
        </form>
      </Box>
    }
  />
));

const addAdditionalFieldToTableEnhance = compose(
  connect(null, { addAdditionalFieldToTable }),
  withFormik({
    mapPropsToValues: ({ prefix }: Object) => ({ prefix, value: '' }),
    validationSchema: Yup.object().shape(
      { value: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()) },
    ),
    handleSubmit: ({ value, prefix }: Object, { props, resetForm }: Object) => {
      resetForm();
      props.addAdditionalFieldToTable({ value, prefix });
    },
  }),
  pure,
);

export const AddAdditionalFieldToTable = addAdditionalFieldToTableEnhance((props: Object) => (
  <PopperWrapper
    text={props.text}
    type='importNumberOfRows'
    popperContent={
      <Box p={15}>
        <form onSubmit={props.handleSubmit}>
          <FieldsetComponent {...G.getFormikProps(props)} fields={R.of(Array, additionalField(props.label))} />
          <ActionButton m='5px' width={200}>
            {G.getWindowLocale('actions:add', 'Add')}
          </ActionButton>
        </form>
      </Box>
    }
  />
));

export const renderValueViewer = ({ cell }: Object) => {
  const { width, label, error } = cell;

  return (
    <TextComponent
      mx='auto'
      fontSize={14}
      maxWidth={120}
      display='block'
      lineHeight='18px'
      textAlign='center'
      color={cell.color}
      withEllipsis={true}
      title={R.or(error, label)}
      minWidth={R.or(width, 120)}
    >
      {R.or(label, error)}
    </TextComponent>
  );
};

export const renderTable = (
  children: Object,
  className: string,
  allChecked: Boolean,
  handleSelectRow: Function,
  emptyRow: Object,
) => {
  const greyColor = G.getTheme('colors.light.grey');

  return (
    <table className={className}>
      <TableHeadWrapper>
        <th>
          <input
            name='all'
            type='checkbox'
            checked={allChecked}
            onChange={handleSelectRow}
          />
        </th>
        <TableHeaderCellWrapper>
          <TextComponent
            fontSize={14}
            color={greyColor}
            textAlign='center'
            width='max-content'
            withEllipsis={true}
          >
            №
          </TextComponent>
        </TableHeaderCellWrapper>
        {R.values(emptyRow).map(({ name, required }: Object, index: number) => (
          <TableHeaderCellWrapper key={index}>
            <TextComponent
              fontSize={14}
              minWidth={120}
              maxWidth={120}
              display='block'
              color={greyColor}
              textAlign='center'
              withEllipsis={true}
              css={G.ifElse(G.isTrue(required), requiredClass)}
              title={G.getWindowLocale(`titles:${name}`, name)}
            >
              {G.getWindowLocale(`titles:${name}`, name)}
            </TextComponent>
          </TableHeaderCellWrapper>
        ))}
      </TableHeadWrapper>
      <tbody>
        {children}
      </tbody>
    </table>
  );
};

export const renderRow = (
  row: number,
  selected: Object,
  children: Object,
  handleSelectRow: Function,
) => (
  <tr>
    <td>
      <input
        name={row}
        type='checkbox'
        onChange={handleSelectRow}
        checked={R.pathOr(false, [row], selected)}
      />
    </td>
    {children}
  </tr>
);

const selectEditorEnhance = compose(
  connect(null, { changeTableCell }),
  withHandlers({
    handleChange: ({ row, cell, onRevert, changeTableCell }: Object) => (option: Object) => {
      if (R.not(option)) return onRevert();

      const { name } = cell;
      const { value, label } = option;

      const path = ['importList', row, name];
      const newCell = R.mergeRight(cell, { value, label, error: null, className: 'success' });

      changeTableCell({ path, cell: newCell });
      onRevert();
    },
  }),
  pure,
);

export const SelectEditor = selectEditorEnhance((props: Object) => {
  const { cell, value, handleChange } = props;

  const additionalStyles = {
    valueContainer: (baseStyles: Object) => ({
      ...baseStyles,
      padding: '0 2px',
    }),
    input: (baseStyles: Object) => ({
      ...baseStyles,
      padding: 0,
      margin: '0 2px',
    }),
    control: (baseStyles: Object) => ({
      ...baseStyles,
      minHeight: 20,
      borderRadius: 1,
    }),
    menu: (baseStyles: Object) => ({
      ...baseStyles,
      textAlign: 'left',
      color: G.getTheme('colors.black'),
    }),
  };

  return (
    <ReactSelect
      height={18}
      value={value}
      autoFocus={true}
      isClearable={false}
      openMenuOnFocus={true}
      options={cell.options}
      onChange={handleChange}
      additionalStyles={additionalStyles}
    />
  );
});

export const readOnlyCell = (value: Object) => ({
  index: 0,
  readOnly: true,
  selected: true,
  width: 'max-content',
  [GC.FIELD_LABEL]: R.inc(value),
});

const optionsMap = {
  branchType: BRANCH_TYPE_OPTIONS,
  payType: C.carrierPayTypeOptions,
  [GC.FIELD_UOM]: FUEL_UOM_OPTIONS,
  originCountries: COUNTRY_OPTIONS,
  licenseClass: LICENSE_CLASS_OPTIONS,
  destinationCountries: COUNTRY_OPTIONS,
  distanceUom: DEFAULT_DISTANCE_UOM_OPTIONS,
  radiusUom: R.drop(1, ITEM_DIMENSIONS_OPTIONS),
  [GC.FIELD_TOLL_CHARGES_AGENCY]: C.agencyOptions,
  licenseRestrictions: LICENSE_RESTRICTION_OPTIONS,
  [GC.FIELD_CURRENCY]: R.drop(1, GC.CURRENCY_OPTIONS),
  transponderIntegrationType: tollChargeSourceOptions,
  [GC.FIELD_TEMPERATURE_UOM]: ITEM_TEMPERATURE_OPTIONS,
  [GC.FIELD_DRIVER_TYPE]: R.values(DRIVER_TYPE_OPTIONS),
  [GC.FIELD_WEIGHT_UOM]: DEFAULT_WEIGHT_RATE_UNIT_OPTIONS,
  [GC.FIELD_RATE_TYPE]: DEFAULT_CARRIER_RATE_TYPE_OPTIONS,
  [GC.FIELD_FUEL_CARDS_PRODUCT_TYPE]: C.productTypeOptions,
  [GC.FIELD_PACKAGE_TYPE]: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  [GC.FIELD_TOLL_CHARGES_TRANSACTION_TYPE]: C.transactionTypeOptions,
  [GC.FIELD_INTEGRATION_TYPE]: {
    [C.IMPORT_TYPE_TRAILER]: trailerIntegrationTypeOptions,
    [C.IMPORT_TYPE_TRUCK]: [...eldOptions, ...tollChargeOptions],
  },
};

export const generateGrid = ({ importType, importList, configOptions, geoFencingZoneList }: Object) => R.compose(
  R.values,
  G.mapIndexed((row: Object, index: number) => R.compose(
    R.prepend(readOnlyCell(index)),
    R.sortBy(R.prop('index')),
    R.values,
    R.map((item: Object) => {
      const { name, dropDownName } = item;

      if (G.isNotNilAndNotEmpty(dropDownName)) {
        const options = R.pathOr([], [dropDownName], configOptions);

        return R.mergeRight(item, { options, dataEditor: SelectEditor });
      }

      if (R.includes(name, R.keys(optionsMap))) {
        const options = R.or(
          R.pathOr([], [name, importType], optionsMap),
          R.pathOr([], [name], optionsMap),
        );

        return R.mergeRight(item, { options, dataEditor: SelectEditor });
      }

      if (R.propEq('BOOLEAN', 'type', item)) {
        return R.mergeRight(item, {
          options: booleanOptions,
          dataEditor: SelectEditor,
        });
      }

      if (R.equals(name, GC.FIELD_RATE_UNIT)) {
        const rateType = G.getPropFromObject(GC.FIELD_RATE_TYPE, row);
        const options = R.pathOr([], [rateType], DEFAULT_RATE_UNIT_GROUP_OPTIONS);

        return R.mergeRight(item, { options, dataEditor: SelectEditor });
      }

      if (R.includes(
        name,
        [C.FIELD_CARRIER_RATE_PRICE_ORIGIN_ZONE_NAME, C.FIELD_CARRIER_RATE_PRICE_DESTINATION_ZONE_NAME],
      )) {
        const options = R.map(({ label }: Object) => ({
          [GC.FIELD_LABEL]: label,
          [GC.FIELD_VALUE]: label,
        }), geoFencingZoneList);

        return R.mergeRight(item, { options, dataEditor: SelectEditor });
      }

      return item;
    }),
  )(row)),
)(R.or(importList, []));

export const makeLocationArray = (data: Object, objectType: string) => R.map(
  (item: Object) => {
    const fields = G.ifElse(
      R.equals(objectType, 'origin'),
      C.ORIGIN_GROUPED_FIELDS,
      C.DESTINATION_GROUPED_FIELDS,
    );
    const location = R.compose(
      R.join(', '),
      R.values,
      R.filter(G.isNotNilAndNotEmpty),
      R.pick(fields),
    )(item);
    const zip = R.prop(`${objectType}Zip`, item);
    const city = R.prop(`${objectType}City`, item);
    const state = R.prop(`${objectType}State`, item);
    const country = R.prop(`${objectType}Country`, item);
    const address1 = R.prop(`${objectType}Address1`, item);
    const templateId = R.prop(`${objectType}TemplateId`, item);

    return {
      zip,
      city,
      state,
      country,
      address1,
      location,
      objectType,
      templateId,
      calculated: false,
    };
  },
  data,
);

export const getSpecificLocationValue = (
  data: Array,
  type: string,
  insuranceType: string,
) => {
  const addressComponent = R.find((location: Object) => (
    G.isNotNil(
      R.find(
        (addressType: string) => (
          R.or(R.equals(type, addressType), R.equals(insuranceType, addressType))
        ),
        location.types,
      ))),
    data,
  );

  return G.getOrElse(addressComponent, 'long_name', '');
};
