import * as R from 'ramda';
import { withFormik } from 'formik';
import React, { useEffect } from 'react';
import { pure, compose, withState, withProps } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { FormFooter2 } from '../../../components/form-footer';
// features
import PC from '../../permission/role-permission';
// forms
import { Checkbox } from '../../../forms/formik/fieldset2/ui';
import { Fieldset2 } from '../../../forms/formik/fieldset2/fieldset';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncInitialData } from '../../../hocs/with-async-initial-data';
import { withAsyncGetDriverListFullName } from '../../../hocs/with-async-get-endpoint-hocs';
// ui
import { Box, Flex, Text } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature payroll
import { validationSchemaChargeObject } from '../validators';
import { rateTypeOptions, chargeInitFields, rateUnitOptionsGroup } from '../settings/payroll-with-charges-settings';
//////////////////////////////////////////////////

const accessorialFieldsToOmit = [
  GC.FIELD_GUID,
  GC.FIELD_VERSION,
  GC.FIELD_CREATED_BY,
  GC.FIELD_CREATED_DATE,
  GC.FIELD_BRANCH_GUID,
  GC.FIELD_LAST_MODIFIED_BY,
  GC.FIELD_ACCESSORIAL_COPY,
  GC.FIELD_LAST_MODIFIED_DATE,
  GC.FIELD_ACCESSORIAL_SERVICE,
  GC.FIELD_ACCESSORIAL_PARENT_GUID,
  GC.FIELD_ACCESSORIAL_FUEL_RELATED,
  GC.FIELD_ACCESSORIAL_TEL_INVOICE_GL,
  GC.FIELD_ACCESSORIAL_CLO_INVOICE_GL,
  GC.FIELD_ACCESSORIAL_ORIGINAL_CONFIG_GUID,
];

const enhance = compose(
  withState('showAllFields', 'setShowAllFields', false),
  withAsyncGetDriverListFullName,
  withProps(({ vendorGuid }: Object) => ({
    asyncEndpoint: endpointsMap.listFleetTrucks,
    asyncOptions: { params: {
      vendorGuid,
      [GC.BRANCH_GUID]: G.ifElse(R.isNil(vendorGuid), G.getAmousCurrentUserBranchGuidFromWindow()),
    }},
  })),
  withAsyncInitialData,
  withProps(({ asyncInitialData, asyncDriverListFullName }: Object) => {
    const truckOptions = G.mapUnitIdGuidObjectPropsToLabelValueObject(
      R.pathOr([], ['data'], asyncInitialData),
    );

    const driverOptions = R.map((item: Object) => {
      const { fullText } = G.getUserInfo(item);

      return { [GC.FIELD_LABEL]: fullText, [GC.FIELD_VALUE]: G.getGuidFromObject(item) };
    }, R.or(asyncDriverListFullName, []));

    return { truckOptions, driverOptions };
  }),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaChargeObject,
    handleSubmit: (values: Object, { props: { submitAction } }: Object) => submitAction(values),
    mapPropsToValues: ({ type, initialValues }: Object) => {
      const { payedForTruckGuid, payedForDriverGuid, forbidUpdatePayedFor } = initialValues;

      let initialValuesToUse = initialValues;

      if (R.and(G.notEquals(type, GC.FIELD_DRIVER), G.isFalse(forbidUpdatePayedFor))) {
        let payedFor = '';

        if (G.isNotNilAndNotEmpty(payedForTruckGuid)) payedFor = GC.FIELD_TRUCK;

        if (G.isNotNilAndNotEmpty(payedForDriverGuid)) payedFor = GC.FIELD_DRIVER;

        initialValuesToUse = R.assoc(GC.FIELD_PAYED_FOR, payedFor, initialValues);
      }

      return G.setInitialFormikValues(chargeInitFields, initialValuesToUse);
    },
  }),
  pure,
);

const commonInputWrapperStyles = { mr: 20, mb: 25 };

const flexInputWrapperStyles = { ...commonInputWrapperStyles, flexGrow: 1 };

const getUpdatedValues = (fieldValue: string, accessorials: Array, initCharge: Object, glCodeMappings: Object) => {
  const accessorial = R.find(R.propEq(fieldValue, GC.FIELD_ACCESSORIAL_DISPLAYED_VALUE), accessorials);
  const assessorialConfigGuid = G.getPropFromObject(GC.FIELD_ACCESSORIAL_ORIGINAL_CONFIG_GUID, accessorial);
  const glCode = R.pathOr('', [GC.INVOICE_MAPPING_TYPE_ASSESSORIALS, assessorialConfigGuid], glCodeMappings);

  return R.compose(
    R.mergeRight({ ...initCharge, glCode, assessorialConfigGuid }),
    R.omit(accessorialFieldsToOmit),
  )(accessorial);
};

const handleChangeDeduction = ({ target }: Object, { fieldName }: any, { values, setFieldValue }: Object) => {
  const { deduction, deductionFromVendor } = values;

  const value = G.getPropFromObject(GC.FIELD_CHECKED, target);

  setFieldValue(fieldName, value);

  if (G.isFalse(value)) return;

  if (R.and(deductionFromVendor, R.equals(fieldName, GC.FIELD_CHARGE_DEDUCTION))) {
    setFieldValue(GC.FIELD_DEDUCTION_FROM_VENDOR, false);
  } else if (deduction) {
    setFieldValue(GC.FIELD_CHARGE_DEDUCTION, false);
  }
};

const handleChangeRateOrQuantity = (event: Object, { fieldName }: any, { values, setFieldValue }: Object) => {
  const { rate, quantity } = values;

  const value = G.getEventTargetValue(event);

  setFieldValue(fieldName, G.isNotNilAndNotEmpty(value) ? G.toNumber(value) : '');

  const updatedTotal = R.multiply(
    value,
    R.or(G.ifElse(R.equals(fieldName, GC.FIELD_CHARGE_RATE), quantity, rate), 1),
  );

  setFieldValue(GC.FIELD_CHARGE_TOTAL, updatedTotal);
};

const getFirstFieldSettings = (type: string) => [
  {
    type: 'toggle',
    shouldCustomChange: true,
    fieldName: GC.FIELD_CHARGE_DEDUCTION,
    label: ['titles:deduction', 'Deduction'],
    customChangeHandler: handleChangeDeduction,
    inputWrapperStyles: { width: 'fit-content' },
  },
  {
    type: 'toggle',
    shouldCustomChange: true,
    fieldName: GC.FIELD_DEDUCTION_FROM_VENDOR,
    customChangeHandler: handleChangeDeduction,
    label: ['titles:deduction-from-vendor', 'Deduction From Vendor'],
    inputWrapperStyles: {
      ml: 30,
      width: 'fit-content',
      display: G.ifElse(R.equals(type, GC.FIELD_DRIVER), 'flex', 'none'),
    },
  },
];

const getSecondtFieldSettings = (shouldGenerateId: boolean) => [
  {
    isRequired: true,
    type: 'reactSelect',
    closeMenuOnScroll: true,
    shouldCustomChange: true,
    useMenuPortalTarget: true,
    options: 'accessorialsOptions',
    fieldName: GC.FIELD_CHARGE_RATE_NAME,
    label: ['titles:charge-name', 'Charge Name'],
    inputWrapperStyles: { ...flexInputWrapperStyles, flexBasis: 160 },
    customChangeHandler: (value: Object, _: any, props: Object) => {
      const { values, setValues, glCodeMappings, accessorialsConfigs } = props;

      const initCharge = R.compose(
        R.when(() => G.isTrue(shouldGenerateId), R.assoc(GC.FIELD_ID, G.genShortId())),
        R.mergeRight(chargeInitFields),
        R.pick([GC.FIELD_ID, GC.FIELD_COMMENTS, GC.FIELD_DEDUCTION, GC.FIELD_DEDUCTION_FROM_VENDOR]),
      )(values);

      const updatedValues = getUpdatedValues(value, accessorialsConfigs, initCharge, glCodeMappings);

      setValues(updatedValues);
    },
  },
  {
    type: 'number',
    isRequired: true,
    errorWidth: '100%',
    shouldCustomChange: true,
    label: ['titles:rate', 'Rate'],
    fieldName: GC.FIELD_CHARGE_RATE,
    customChangeHandler: handleChangeRateOrQuantity,
    inputWrapperStyles: { ...flexInputWrapperStyles, maxWidth: 85, flexBasis: 65 },
  },
  {
    type: 'number',
    isRequired: true,
    errorWidth: '100%',
    shouldCustomChange: true,
    fieldName: GC.FIELD_CHARGE_QUANTITY,
    label: ['titles:quantity', 'Quantity'],
    customChangeHandler: handleChangeRateOrQuantity,
    inputWrapperStyles: { ...flexInputWrapperStyles, maxWidth: 80, flexBasis: 65 },
  },
  {
    type: 'select',
    isRequired: true,
    shouldCustomChange: true,
    options: rateTypeOptions,
    fieldName: GC.FIELD_CHARGE_RATE_TYPE,
    label: ['titles:rate-type', 'Rate Type'],
    inputWrapperStyles: { ...flexInputWrapperStyles, mr: 0, maxWidth: 100, flexBasis: 85 },
    customChangeHandler: (event: Object, _: Object, { handleChange, setFieldValue }: Object) => {
      handleChange(event);
      setFieldValue(GC.FIELD_CHARGE_RATE_UNIT, '');
    },
  },
  {
    type: 'select',
    options: 'rateUnitOptions',
    fieldName: GC.FIELD_CHARGE_RATE_UNIT,
    label: ['titles:rate-unit', 'Rate Unit'],
    inputWrapperStyles: {
      ml: 20,
      mb: 25,
      flexGrow: 1,
      flexBasis: 100,
      display: ({ values: { rateType } }: Object) => G.ifElse(
        R.or(G.isNilOrEmpty(rateType), R.includes(rateType, [GC.CHARGE_RATE_TYPE_FLAT, GC.CHARGE_RATE_TYPE_STOP])),
        'none',
        'block',
      ),
    },
  },
];

const getThirdFieldSettings = (type: string, showAllFields: boolean) => [
  {
    type: 'textarea',
    inputStyles: { height: 60 },
    fieldName: GC.FIELD_COMMENTS,
    label: ['titles:comments', 'Comments'],
    inputWrapperStyles: { mb: 25, width: '100%' },
  },
  {
    type: 'select',
    shouldCustomChange: true,
    fieldName: GC.FIELD_PAYED_FOR,
    label: ['titles:payed-for', 'Payed For'],
    inputWrapperStyles: {
      ...commonInputWrapperStyles,
      width: 100,
      display: ({ values: { forbidUpdatePayedFor } }: Object) => G.ifElse(
        R.or(R.equals(type, GC.FIELD_DRIVER), G.isTrue(forbidUpdatePayedFor)),
        'none',
        'block',
      ),
    },
    options: [
      GC.EMPTY_OPTION_OBJECT,
      {
        value: GC.FIELD_TRUCK,
        label: G.getWindowLocale('titles:truck', 'Truck'),
      },
      {
        value: GC.FIELD_DRIVER,
        label: G.getWindowLocale('titles:driver', 'Driver'),
      },
    ],
    customChangeHandler: ({ currentTarget: { value } }: Object, _: any, { values, setValues }: Object) => {
      const newValues = R.mergeRight(
        values,
        {
          [GC.FIELD_PAYED_FOR]: value,
          [GC.FIELD_PAYED_FOR_TRUCK]: null,
          [GC.FIELD_PAYED_FOR_DRIVER]: null,
          [GC.FIELD_PAYED_FOR_TRUCK_GUID]: null,
          [GC.FIELD_PAYED_FOR_DRIVER_GUID]: null,
        },
      );

      setValues(newValues);
    },
  },
  {
    type: 'reactSelect',
    options: 'truckOptions',
    shouldCustomChange: true,
    label: ['titles:truck', 'Truck'],
    fieldName: GC.FIELD_PAYED_FOR_TRUCK_GUID,
    inputWrapperStyles: {
      ...commonInputWrapperStyles,
      width: 165,
      display: ({ values: { payedFor } }: Object) => G.ifElse(R.equals(GC.FIELD_TRUCK, payedFor), 'flex', 'none'),
    },
    customChangeHandler: (value: Object, _: any, props: Object) => {
      const { values, setValues, truckOptions } = props;

      if (G.isNilOrEmpty(value)) {
        return setValues(R.mergeRight(values, {
          [GC.FIELD_PAYED_FOR_TRUCK]: null,
          [GC.FIELD_PAYED_FOR_TRUCK_GUID]: null,
        }));
      }

      const unitId = R.pathOr('', [GC.FIELD_LABEL], R.find(R.propEq(value, GC.FIELD_VALUE), truckOptions));

      setValues(R.mergeRight(values, {
        [GC.FIELD_PAYED_FOR_TRUCK_GUID]: value,
        [GC.FIELD_PAYED_FOR_TRUCK]: { unitId, [GC.FIELD_GUID]: value },
      }));
    },
  },
  {
    type: 'reactSelect',
    options: 'driverOptions',
    shouldCustomChange: true,
    label: ['titles:driver', 'Driver'],
    fieldName: GC.FIELD_PAYED_FOR_DRIVER_GUID,
    inputWrapperStyles: {
      ...commonInputWrapperStyles,
      width: 165,
      display: ({ values: { payedFor } }: Object) => G.ifElse(R.equals(GC.FIELD_DRIVER, payedFor), 'flex', 'none'),
    },
    customChangeHandler: (value: Object, _: any, props: Object) => {
      const { values, setValues, asyncDriverListFullName } = props;

      if (G.isNilOrEmpty(value)) {
        return setValues(R.mergeRight(values, {
          [GC.FIELD_PAYED_FOR_DRIVER]: null,
          [GC.FIELD_PAYED_FOR_DRIVER_GUID]: null,
        }));
      }

      const payedForDriver = R.compose(
        R.pick([GC.FIELD_GUID, GC.FIELD_FIRST_NAME, GC.FIELD_LAST_NAME, GC.FIELD_USER_LOGIN_ID]),
        R.find(R.propEq(value, GC.FIELD_GUID)),
      )(asyncDriverListFullName);

      setValues(R.mergeRight(values, {
        [GC.FIELD_PAYED_FOR_DRIVER_GUID]: value,
        [GC.FIELD_PAYED_FOR_DRIVER]: payedForDriver,
      }));
    },
  },
  {
    type: 'select',
    options: GC.CURRENCY_OPTIONS_2,
    fieldName: GC.FIELD_CHARGE_CURRENCY,
    label: ['titles:currency', 'Currency'],
    inputWrapperStyles: {
      ...commonInputWrapperStyles,
      width: 100,
      display: G.ifElse(showAllFields, 'block', 'none'),
    },
  },
  {
    type: 'select',
    options: 'glCodeOptions',
    fieldName: GC.FIELD_GL_CODE,
    label: ['titles:gl-code', 'GL Code'],
    disabled: () => G.hasNotAmousCurrentUserPermissions(PC.GL_CODE_WRITE),
    inputWrapperStyles: {
      ...flexInputWrapperStyles,
      mr: 0,
      maxWidth: 165,
      flexBasis: 130,
      display: G.ifElse(showAllFields, 'block', 'none'),
    },
  },
];

const TotalComponent = ({ values, payrollCurrency }: Object) => {
  const deduction = G.getPropFromObject(GC.FIELD_DEDUCTION, values);
  const total = G.NaNToZero(G.toFixed(G.getTotalFromCharge(values), 2));

  const currencySymbol = G.getCurrencySymbol(
    R.or(G.getPropFromObject(GC.FIELD_CHARGE_CURRENCY, values), payrollCurrency),
  );

  const text = `${currencySymbol} ${G.ifElse(G.isTrue(deduction), '-', '')}${total}`;

  return (
    <Flex mt={130} justifyContent='center' color={G.getTheme('colors.dark.blue')}>
      <Text fontSize={14} fontWeight='bold' wordBreak='normal'>
        {`${G.getWindowLocale('titles:total', 'Total')}:`}
      </Text>
      <TextComponent ml={10} title={text} fontSize={14} fontWeight='bold' withEllipsis={true}>
        {text}
      </TextComponent>
    </Flex>
  );
};

const getOptionsFromAccessorials = R.map(({ displayedValue }: Object) => ({
  [GC.FIELD_VALUE]: displayedValue,
  [GC.FIELD_LABEL]: displayedValue,
}));

export const PayrollChargeForm = enhance((props: Object) => {
  const {
    type,
    values,
    asyncConfigs,
    handleSubmit,
    truckOptions,
    driverOptions,
    showAllFields,
    glCodeMappings,
    payrollCurrency,
    vendorBranchGuid,
    setShowAllFields,
    shouldGenerateId,
    accessorialsConfigs,
    getInitialDataRequest,
    asyncDriverListFullName,
    getAsyncGetDriverListFullName,
  } = props;

  const { rateType, payedFor, forbidUpdatePayedFor } = values;

  useEffect(() => {
    if (R.or(R.equals(type, GC.FIELD_DRIVER), G.isTrue(forbidUpdatePayedFor))) return;

    if (R.and(G.isNilOrEmpty(truckOptions), R.equals(payedFor, GC.FIELD_TRUCK))) {
      getInitialDataRequest();
    }

    if (R.and(G.isNilOrEmpty(driverOptions), R.equals(payedFor, GC.FIELD_DRIVER))) {
      getAsyncGetDriverListFullName({ branchGuid: vendorBranchGuid });
    }
  }, [payedFor]);

  const glCodeOptions = G.addEmptyOptionToDropDown(
    G.createOptionsFromDropdownConfigWithGuidOrParentGuid(asyncConfigs, GC.INVOICE_GL_CODE),
    G.getWindowLocale('titles:gl-code', 'GL Code'),
  );

  return (
    <form onSubmit={handleSubmit}>
      <Flex mb={25} justifyContent='space-between'>
        <Flex>
          <Checkbox
            type='checkbox'
            checked={showAllFields}
            onChange={() => setShowAllFields((prev: boolean) => R.not(prev))}
          />
          <Box mx={10} fontSize={11} color={G.getTheme('colors.greyMatterhorn')}>
            {G.getWindowLocale('titles:show-all-fields', 'Show All Fields')}
          </Box>
        </Flex>
        <Fieldset2 {...G.getFormikPropsToFieldset(props)} fields={getFirstFieldSettings(type)} />
      </Flex>
      <Fieldset2
        {...G.getFormikPropsToFieldset(props)}
        glCodeMappings={glCodeMappings}
        accessorialsConfigs={accessorialsConfigs}
        fields={getSecondtFieldSettings(shouldGenerateId)}
        additionFieldComponentProps={['glCodeMappings', 'accessorialsConfigs']}
        accessorialsOptions={getOptionsFromAccessorials(R.or(accessorialsConfigs, []))}
        rateUnitOptions={G.prependEmptyLabelValueOption(G.getPropFromObject(rateType, rateUnitOptionsGroup))}
      />
      <Fieldset2
        {...G.getFormikPropsToFieldset(props)}
        truckOptions={truckOptions}
        driverOptions={driverOptions}
        glCodeOptions={R.or(glCodeOptions, [])}
        asyncDriverListFullName={asyncDriverListFullName}
        fields={getThirdFieldSettings(type, showAllFields)}
      />
      <Box mt={-15} height='1px' width='100%' borderTop='1px solid' borderColor={G.getTheme('colors.bgGrey')} />
      <TotalComponent values={values} payrollCurrency={payrollCurrency} />
      <FormFooter2 />
    </form>
  );
});
