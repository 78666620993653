import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { compose, withState, withHandlers } from 'react-recompose';
// common
import { makeSelectDocumentTemplates, makeSelectMailIntegrationType } from '../../../common/selectors';
// components
import { Table } from '../../../components/table';
import { Tabs2 } from '../../../components/tabs-mui';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { getConfirmModal } from '../../../components/confirm';
import { PageActions } from '../../../components/page-actions';
import { withPromptModal } from '../../../components/edit-report/hocs';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import { QuickFilterOutsideControl3 } from '../../../components/quick-filter';
import { withShowDocuments } from '../../../components/async-documents/with-show-documents';
// features
import Audit2 from '../../audit2';
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
import { startPivotTable } from '../../pivot-table/actions';
import { makeSelectOpenedSidebar } from '../../navbar-menu/selectors';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import { makeSelectBranchesTree, makeSelectCurrentBranchGuid } from '../../branch/selectors';
// forms
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
import SelectPrintTemplateForm from '../../../forms/forms/select-print-template-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
import { withAsyncEditDriverInvoice } from '../../../hocs/with-async-edit-driver-invoice';
// report-common
import { reportEnhancer, reportBranchEnhancer } from '../../../report-common';
// icons
import * as I from '../../../svgs';
// ui
import { Box, IconWrapper, ListWrapper, ZOrderWrapper } from '../../../ui';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature payroll
import * as H from '../helpers';
import AddPayroll from '../components/add-payroll';
import { FILTER_PARAMS } from '../settings/filter-params';
import PayrollActions from '../components/payroll-actions';
import { startVendorPayroll } from '../vendor-payroll/actions';
import PayrollWithCharges from '../components/payroll-with-charges';
import { driversListReport } from '../settings/drivers-report-settings';
import UpdatePayrollInvoices from '../components/update-payroll-invoices';
import { makeSelectVendorPayrollVisited } from '../vendor-payroll/selectors';
import CreatePayrollsWithoutTrips from '../components/create-payroll-without-trips';
import { columnSettings, getTableSettings } from '../settings/payroll-list-table-settings';
import {
  selectItem,
  setReports,
  addPayrolls,
  setUsedReport,
  cleanQuickFilter,
  getPayrollDetails,
  setTableTitleSort,
  getItemListRequest,
  qbIIFImportRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  printPayrollRequest,
  customExportRequest,
  getPayrollXMLRequest,
  setQuickFilterParams,
  printByReportRequest,
  updatePayrollInvoices,
  getXMLByReportRequest,
  chaseCSVExportRequest,
  resetListAndPagination,
  sendToQuickBookRequest,
  exportReportDataRequest,
  removeInvoiceFromPayroll,
  updateDriverInvoiceRequest,
  changeDefaultReportRequest,
  handleUpdatePayrollRequest,
  deleteDriverPayrollsRequest,
  createDriversPayrollRequest,
} from '../actions';
import {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectPayrollConfigs,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectAvailableCustomExport,
} from '../selectors';
//////////////////////////////////////////////////

export const FilterByTELReference = ({ handleChangeFilterByTELReference }: Object) => (
  <Box ml={15} mb='4px'>
    <QuickFilterOutsideControl3
      handleSetFilter={handleChangeFilterByTELReference}
      placeholder={G.getWindowLocale('titles:search', 'Search')}
      label={`
        ${G.getWindowLocale('titles:search-by', 'Search By')} ${
        G.getWindowLocale('titles:tel-reference', 'TEL Reference')}
      `}
    />
  </Box>
);

const enhance = compose(
  withFixedPopover,
  withAsyncEditDriverInvoice({ updateFrom: 'payrolls' }),
  withShowDocuments,
  reportEnhancer,
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleOpenUpdatePayrollInvoices: (props: Object) => ({ payroll, callback }: Object) => {
      const { configs, openModal, closeModal, updatePayrollInvoices } = props;

      const component = (
        <UpdatePayrollInvoices
          payroll={payroll}
          asyncMethod='post'
          closeModal={closeModal}
          initialFilterDays={configs.invoiceFilterDateRange}
          asyncEndpoint={endpointsMap.getInvoicesToUpdateDriverPayroll}
          updatePayrollInvoices={(data: Object) => updatePayrollInvoices({ ...data, callback })}
        />
      );

      const modal = H.getUpdatePayrollInvoicesModalWithContent(component);

      openModal(modal);
    },
    handleExportPayrollsToQuickBook: (props: Object) => (desktop: any) => {
      const { itemList, sendToQuickBookRequest } = props;

      const selectedList = R.filter(R.prop('selected'), itemList);

      if (R.propEq(0, 'length', selectedList)) return;

      const fileName = R.join('_', R.map(({ payrollNumber }: Object) => payrollNumber, selectedList));
      const fileGuids = R.map(({ guid }: Object) => guid, selectedList);

      sendToQuickBookRequest({ fileName, desktop, fileGuids });
    },
    handlePrintPayroll: (props: Object) => (payroll: Object) => {
      const {
        openModal,
        closeModal,
        documentTemplates,
        printPayrollRequest,
        printByReportRequest,
      } = props;

      const driverPayrollDocumentTemplates = G.getPropFromObject(
        GC.DOCUMENT_PRINTABLE_SECTION_DRIVER_PAYROLL,
        documentTemplates,
      );

      if (G.isNilOrEmpty(driverPayrollDocumentTemplates)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:no-configured-document-templates', 'There are no configured document templates'),
        );
      }

      let component;
      let payrollGuids;

      if (R.isNil(payroll)) {
        const selectedList = R.filter(R.prop('selected'), props.itemList);

        if (G.isNilOrEmpty(selectedList)) {
          component = (
            <SelectPrintTemplateForm
              submitAction={(values: Object) => printByReportRequest(values)}
              printableSection={GC.DOCUMENT_PRINTABLE_SECTION_DRIVER_PAYROLL_REPORT}
            />
          );
        } else {
          payrollGuids = R.map(({ guid }: Object) => guid, selectedList);
        }
      } else {
        payrollGuids = R.of(Array, payroll.guid);
      }

      if (G.isNotNilAndNotEmpty(payrollGuids)) {
        const options = R.map(
          (template: Object) => ({ value: template.guid, label: template.name }),
          driverPayrollDocumentTemplates,
        );

        component = (
          <SelectDropdownForm
            fieldLabel=' '
            options={options}
            cancelAction={closeModal}
            submitAction={(value: string) => (
              printPayrollRequest({
                payrollGuids,
                templateGuid: value,
              })
            )}
          />
        );
      }

      const modal = {
        p: 15,
        component,
        options: {
          height: 'auto',
          maxWidth: '95vw',
          maxHeight: '95vh',
          width: 'fit-content',
          title: G.getWindowLocale('titles:select-template', 'Select Template'),
        },
      };

      openModal(modal);
    },
    handleCustomExport: (props: Object) => (invoiceGuid: string, byReport: boolean) => {
      const { itemList, selectedReport, customExportRequest } = props;

      const infoMessage = () => G.showToastrMessageSimple(
        'info',
        G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
      );

      if (G.isTrue(byReport)) {
        if (G.isNilOrEmpty(itemList)) return infoMessage();

        return customExportRequest({
          ...R.pick(['fields', 'orderFields', 'searchCriteria'], selectedReport),
          [GC.CURRENT_BRANCH]: G.getAmousCurrentBranchGuidFromWindow(),
        });
      }

      const guids = G.getGuidsFromStringOrSelectedList(invoiceGuid, itemList);

      if (R.isEmpty(guids)) return infoMessage();

      customExportRequest(guids);
    },
    handleShowAudit: (props: Object) => ({ guid, payrollNumber }: Object) => {
      const { openModal, closeFixedPopup } = props;

      closeFixedPopup();

      const requestOptions = {
        [GC.FIELD_OBJECT_GUID]: guid,
        [GC.FIELD_TYPE]: GC.AUDIT_TYPE_PAYROLL,
      };

      const component = <Audit2 requestOptions={requestOptions} />;

      const modal = {
        p: '0',
        component,
        options: {
          boxShadow: 'none',
          withCloseIcon: true,
          withBorderRadius: true,
          backgroundColor: 'transparent',
          title: `${G.getWindowLocale('titles:audit', 'Audit')}: ${payrollNumber}`,
        },
      };

      openModal(modal);
    },
  }),
  withState('nextPayrollGuid', 'setNextPayrollGuid', null),
  withState('prevPayrollGuid', 'setPrevPayrollGuid', null),
  withHandlers({
    handleToggleDetails: ({ getPayrollDetails }: Object) => (payroll: Object) => getPayrollDetails(payroll),
    handleOpenEditPayrollForm: (props: Object) => (payrollGuid: string, payroll: Object) => {
      const {
        itemList,
        openModal,
        closeModal,
        branchGuid,
        openLoader,
        closeLoader,
        handleShowAudit,
        setPrevPayrollGuid,
        setNextPayrollGuid,
        handlePrintPayroll,
        getItemListRequest,
        handleShowDocuments,
        mailIntegrationType,
        getPayrollXMLRequest,
        resetListAndPagination,
        sendToQuickBookRequest,
        handleUpdatePayrollRequest,
        handleOpenUpdatePayrollInvoices,
      } = props;

      const nextPayrollGuid = R.path([R.inc(payroll.index), GC.FIELD_GUID], itemList);

      const prevPayrollGuid = R.path([R.dec(payroll.index), GC.FIELD_GUID], itemList);

      const driverGuid = R.prop(GC.GRC.DRIVER_GUID, payroll);

      const endpoint = endpointsMap.getDriverPayrollDetailsEndpoint(payrollGuid);

      const initialFilterDates = R.pick([GC.FIELD_PAYROLL_DATE_TO, GC.FIELD_PAYROLL_DATE_FROM], payroll);

      const driverText = `${
        R.pathOr('', [GC.GRC.DRIVER_FIRST_NAME], payroll)} ${
        R.pathOr('', [GC.GRC.DRIVER_LAST_NAME], payroll)} (${
        R.path([GC.GRC.DRIVER_LOGIN_ID], payroll)
      })`;

      const quickBookData = {
        guids: R.of(Array, payrollGuid),
        [GC.FIELD_BRANCH_GUID]: payroll[GC.FIELD_BRANCH_GUID],
      };

      const component = (
        <PayrollWithCharges
          type='driver'
          editMode={true}
          openModal={openModal}
          driverText={driverText}
          driverGuid={driverGuid}
          closeModal={closeModal}
          openLoader={openLoader}
          branchGuid={branchGuid}
          asyncEndpoint={endpoint}
          payrollGuid={payrollGuid}
          closeLoader={closeLoader}
          initialFilterDates={initialFilterDates}
          getItemListRequest={getItemListRequest}
          mailIntegrationType={mailIntegrationType}
          resetListAndPagination={resetListAndPagination}
          handleSendDriverPayroll={handleUpdatePayrollRequest}
          setNextPayrollGuid={() => setNextPayrollGuid(nextPayrollGuid)}
          setPrevPayrollGuid={() => setPrevPayrollGuid(prevPayrollGuid)}
          // actions
          handleShowDocuments={() => handleShowDocuments(
            G.getGuidFromObject(payroll),
            GC.DOCUMENTS_TYPE_DRIVER_PAYROLL,
            driverGuid,
          )}
          handleShowAudit={() => handleShowAudit(payroll)}
          handlePrintPayroll={() => handlePrintPayroll(payroll)}
          getPayrollXMLRequest={() => getPayrollXMLRequest(payroll)}
          handleSendToQuickBook={() => sendToQuickBookRequest(quickBookData)}
          handleOpenUpdatePayrollInvoices={
            (callback: Function) => handleOpenUpdatePayrollInvoices({ payroll, callback })
          }
        />
      );

      const modal = G.getFullWindowModalWithContent(component);

      openModal(modal);
    },
    handleOpenAddDriversPayrollForm: (props: Object) => () => {
      const {
        configs,
        openModal,
        closeModal,
        branchGuid,
        openLoader,
        closeLoader,
        createDriversPayrollRequest,
      } = props;

      if (G.isFalse(configs.autoPayrollNumber)) {
        const modalContent = getConfirmModal({
          cancelAction: closeModal,
          cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
          submitText: G.getWindowLocale('actions:go-to-config', 'Go To Config'),
          text: G.getWindowLocale(
            'messages:setup-payroll-number-autogenerated',
            'You need to setup autogenerated payroll number',
          ),
          submitAction: () => {
            G.goToRoute(GC.ROUTE_PATH_CONFIG_INVOICES);
            closeModal();
          },
        });

        return openModal(modalContent);
      }

      const searchCriteria = R.of(Array, {
        operation: 'equal',
        booleanValue: true,
        dataType: 'boolean',
        propertyName: 'employed',
      });

      const asyncOptions = {
        data: {
          searchCriteria,
          pageable: false,
          fields: driversListReport.fields,
          [GC.FIELD_CURRENT_BRANCH]: branchGuid,
        },
      };

      const component = (
        <CreatePayrollsWithoutTrips
          editMode={false}
          openModal={openModal}
          closeModal={closeModal}
          openLoader={openLoader}
          branchGuid={branchGuid}
          closeLoader={closeLoader}
          asyncReportOptions={asyncOptions}
          asyncReportEndpoint={endpointsMap.listFleetDriversDown}
          handleCreateDriversPayroll={createDriversPayrollRequest}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          width: 900,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '95vh',
          title: G.getWindowLocale('titles:add-payroll', 'Add Payroll'),
        },
      };

      openModal(modal);
    },
    handleOpenSubmitDeletePayroll: (props: Object) => (guid: string, payroll: Object) => {
      const {
        openModal,
        closeModal,
        deleteDriverPayrollsRequest,
      } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        name: payroll.payrollNumber,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale('messages:delete-confirmation-text-double', 'Are you sure you want to delete'),
        submitAction: () => {
          deleteDriverPayrollsRequest(R.of(Array, guid));
          closeModal();
        },
      });

      openModal(modalContent);
    },
    handleOpenSubmitDeletePayrolls: (props: Object) => () => {
      const { itemList, openModal, closeModal, deleteDriverPayrollsRequest } = props;

      const selectedList = R.filter(
        R.prop('selected'),
        itemList,
      );

      if (R.propEq(0, 'length', selectedList)) return;

      const modalContent = getConfirmModal({
        cancelAction: props.closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        name: R.join(', ', R.map(({ payrollNumber }: Object) => payrollNumber, selectedList)),
        text: G.getWindowLocale('messages:delete-confirmation-text-double', 'Are you sure you want to delete'),
        submitAction: () => {
          deleteDriverPayrollsRequest(R.map(
            ({ guid }: Object) => guid,
            selectedList,
          ));
          closeModal();
        },
      });

      openModal(modalContent);
    },
  }),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleChangeFilterByTELReference: (props: Object) => (filterString: string) => {
      const { setQuickFilterParams, getItemListRequest, resetListAndPagination } = props;

      let quickFilterParams = {};

      if (G.isNotNilAndNotEmpty(filterString)) {
        quickFilterParams = {
          dataType: 'string',
          dateRelative: false,
          operation: 'contain',
          stringValue: filterString,
          propertyName: GC.GRC.INVOICES_TEL_PRIMARY_REFERENCE_VALUE,
        };
      }

      setQuickFilterParams(quickFilterParams);
      resetListAndPagination();
      getItemListRequest(true);
    },
    handleClickEditIcon: (props: Object) => (e: Object, payroll: Object) => {
      const {
        openFixedPopup,
        handleShowAudit,
        closeFixedPopup,
        handlePrintPayroll,
        qbIIFImportRequest,
        handleCustomExport,
        handleShowDocuments,
        getPayrollXMLRequest,
        chaseCSVExportRequest,
        sendToQuickBookRequest,
        handleOpenUpdatePayrollInvoices,
      } = props;

      const dataToSend = {
        guids: R.of(Array, payroll.guid),
        [GC.FIELD_BRANCH_GUID]: payroll[GC.FIELD_BRANCH_GUID],
      };

      openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <PayrollActions
            payroll={payroll}
            closeFixedPopup={closeFixedPopup}
            handleCustomExport={handleCustomExport}
            qbIIFImportRequest={qbIIFImportRequest}
            chaseCSVExportRequest={chaseCSVExportRequest}
            handleShowAudit={() => handleShowAudit(payroll)}
            hasCustomExportAction={props.availableCustomExport}
            handlePrintPayroll={() => handlePrintPayroll(payroll)}
            handleGetPayrollXMLRequest={() => getPayrollXMLRequest(payroll)}
            openUpdatePayrollInvoices={() => handleOpenUpdatePayrollInvoices({ payroll })}
            handleSendToQuickBookRequest={(desktop: any) => sendToQuickBookRequest(
              R.assoc('desktop', desktop, dataToSend),
            )}
            handleShowStatement={() => handleShowDocuments(
              G.getGuidFromObject(payroll),
              GC.DOCUMENTS_TYPE_DRIVER_PAYROLL,
              R.prop(GC.GRC.DRIVER_GUID, payroll),
            )}
          />
        ),
      });
    },
    handleOpenAddPayrolls: (props: Object) => () => {
      const {
        configs,
        openModal,
        closeModal,
        branchGuid,
        addPayrolls,
        openFixedPopup,
        closeFixedPopup,
      } = props;

      const component = (
        <AddPayroll
          asyncMethod='post'
          openModal={openModal}
          closeModal={closeModal}
          branchGuid={branchGuid}
          addPayrolls={addPayrolls}
          openFixedPopup={openFixedPopup}
          closeFixedPopup={closeFixedPopup}
          payrollDayTo={configs.payrollDayTo}
          payrollDayFrom={configs.payrollDayFrom}
          initialPayrollDays={configs.payrollDateRange}
          autoPayrollNumber={configs.autoPayrollNumber}
          asyncEndpoint={endpointsMap.payrollDriverList}
          initialFilterDays={configs.invoiceFilterDateRange}
          updateDriverInvoiceRequest={props.updateDriverInvoiceRequest}
        />
      );

      const modal = H.getPayrollModalWithContent(component);

      openModal(modal);
    },
    handleRemoveInvoiceFromPayroll: (props: Object) => (
      invoiceGuid: string,
      invoiceNumber: string,
      driverPayrollGuid: string,
    ) => {
      const { openModal, closeModal, removeInvoiceFromPayroll } = props;

      const modalContent = getConfirmModal({
        name: invoiceNumber,
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale('messages:delete-confirmation-text-double', 'Are you sure you want to delete'),
        submitAction: () => {
          removeInvoiceFromPayroll({ invoiceGuid, driverPayrollGuid });
          closeModal();
        },
      });

      openModal(modalContent);
    },
    handleQBIIFImportList: (props: Object) => (invoiceGuid: string) => {
      const { itemList, qbIIFImportRequest } = props;

      const guids = G.getGuidsFromStringOrSelectedList(invoiceGuid, itemList);

      if (R.isEmpty(guids)) {
        G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      qbIIFImportRequest(guids);
    },
    handleChaseCSVExport: (props: Object) => (invoiceGuid: string) => {
      const { itemList, chaseCSVExportRequest } = props;

      const guids = G.getGuidsFromStringOrSelectedList(invoiceGuid, itemList);

      if (R.isEmpty(guids)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      chaseCSVExportRequest(guids);
    },
  }),
  reportBranchEnhancer,
);

const PayrollTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    filterParams,
    openedSidebar,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleToggleDetails,
    handleTableTitleFilter,
    handleEditDriverInvoice,
    handleOpenEditPayrollForm,
    handleOpenSubmitDeletePayroll,
    handleRemoveInvoiceFromPayroll,
  } = props;

  const elementActionsComponent = (data: Object) => (
    <AuthWrapper has={[PC.DRIVER_PAYROLL_READ, PC.DRIVER_PAYROLL_WRITE]}>
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  const allChecked = G.isAllChecked(itemList);

  let enhancedItemList = itemList;

  if (G.isNotNil(enhancedItemList)) {
    enhancedItemList = R.map(
      (entity: Object) => R.mergeRight(
        entity,
        {
          detailsWidth: `calc(100vw - ${
            G.ifElse(
              openedSidebar,
              R.add(GC.SIDEBAR_CONTAINER_WIDTH, 32),
              32,
            )
          }px)`,
          editInvoice: handleEditDriverInvoice,
          removeInvoice: handleRemoveInvoiceFromPayroll,
        },
      ),
      enhancedItemList,
    );
  }

  const getPermissions = ({ completed }: Object) => G.ifElse(
    G.isTrue(completed),
    [PC.DRIVER_PAYROLL_OVERWRITE_EXECUTE],
    [PC.DRIVER_PAYROLL_WRITE, PC.DRIVER_PAYROLL_OVERWRITE_EXECUTE],
  );

  const actionButtons = [
    {
      getPermissions,
      iconName: 'pencil',
      action: handleOpenEditPayrollForm,
    },
    {
      iconName: 'trash',
      action: handleOpenSubmitDeletePayroll,
      permissions: [PC.DRIVER_PAYROLL_DELETE_EXECUTE],
    },
  ];

  const data = {
    loading,
    allChecked,
    totalCount,
    pagination,
    actionButtons,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    report: selectedReport,
    handleTableTitleFilter,
    onOptionClick: selectItem,
    itemList: enhancedItemList,
    withResizableColumns: true,
    toggle: handleToggleDetails,
    useNewTitleFilterInputs: true,
    handleLoadMoreEntities: getItemListRequest,
    renderRightStickedComponent: elementActionsComponent,
    filterProps: R.indexBy(R.prop(GC.FIELD_VALUE), FILTER_PARAMS),
    tableSettings: getTableSettings({ reportList, filterParams, selectedReport }),
  };

  return <Table {...data} />;
};

const getListActionsOpt = (props: Object) => {
  const mainLightColor = G.getTheme('colors.light.mainLight');

  let options = [
    {
      action: props.getXMLByReportRequest,
      icon: I.downloadDocument(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:get-xml-file', 'Get XML File'),
    },
    {
      type: 'massAction',
      icon: I.quickbook('transparent', 25, 25),
      action: props.handleExportPayrollsToQuickBook,
      text: G.getWindowLocale('actions:export-to-QB', 'Export to QuickBooks'),
      permissions: [PC.DRIVER_PAYROLL_WRITE, PC.DRIVER_PAYROLL_OVERWRITE_EXECUTE],
    },
    {
      type: 'massAction',
      icon: I.quickbook('transparent', 25, 25),
      action: () => props.handleExportPayrollsToQuickBook(true),
      permissions: [PC.DRIVER_PAYROLL_WRITE, PC.DRIVER_PAYROLL_OVERWRITE_EXECUTE],
      text: G.getWindowLocale('actions:export-to-QB-desktop', 'Export to QuickBooks Desktop'),
    },
    {
      type: 'massAction',
      action: props.handleQBIIFImportList,
      icon: I.quickbook('transparent', 25, 25),
      text: G.getWindowLocale('actions:qb-iif-export', 'QuickBooks IIF Export'),
      permissions: [
        PC.DRIVER_PAYROLL_READ,
        PC.DRIVER_PAYROLL_WRITE,
        PC.DRIVER_PAYROLL_OVERWRITE_EXECUTE,
      ],
    },
    {
      type: 'massAction',
      action: props.handleChaseCSVExport,
      icon: I.downloadDocument(mainLightColor, 25, 25),
      text: G.getWindowLocale('actions:chase-csv-export', 'Chase CSV Export'),
      permissions: [
        PC.DRIVER_PAYROLL_READ,
        PC.DRIVER_PAYROLL_WRITE,
        PC.DRIVER_PAYROLL_OVERWRITE_EXECUTE,
      ],
    },
    {
      type: 'massAction',
      icon: I.printer(mainLightColor, 20, 20),
      action: () => props.handlePrintPayroll(null),
      text: G.getWindowLocale('actions:print', 'Print'),
    },
    {
      type: 'massAction',
      icon: I.trash(mainLightColor, 20, 20),
      action: props.handleOpenSubmitDeletePayrolls,
      permissions: [PC.DRIVER_PAYROLL_DELETE_EXECUTE],
      text: G.getWindowLocale('actions:delete', 'Delete'),
    },
    {
      action: props.handleOpenAddDriversPayrollForm,
      icon: I.createCloIcon(mainLightColor, 40, 40, 'transparent'),
      text: G.getWindowLocale('actions:create-payrolls-without-trips', 'Create Payrolls Without Trips'),
    },
  ];

  if (props.availableCustomExport) {
    const customExportOptions = [
      {
        type: 'massAction',
        action: props.handleCustomExport,
        icon: I.downloadDocument(mainLightColor, 25, 25),
        text: G.getWindowLocale('actions:custom-export', 'Custom Export'),
        permissions: [
          PC.DRIVER_PAYROLL_READ,
          PC.DRIVER_PAYROLL_WRITE,
          PC.DRIVER_PAYROLL_OVERWRITE_EXECUTE,
        ],
      },
      {
        action: () => props.handleCustomExport('', true),
        icon: I.downloadDocument(mainLightColor, 25, 25),
        text: G.getWindowLocale('actions:custom-export-by-report', 'Custom Export By Report'),
        permissions: [
          PC.DRIVER_PAYROLL_READ,
          PC.DRIVER_PAYROLL_WRITE,
          PC.DRIVER_PAYROLL_OVERWRITE_EXECUTE,
        ],
      },
    ];

    options = R.concat(options, customExportOptions);
  }

  return options;
};

const DriverPayrolls = (props: Object) => {
  const {
    itemList,
    activeTab,
    totalCount,
    selectedReport,
    prevPayrollGuid,
    nextPayrollGuid,
    startPivotTable,
    handleSetActiveTab,
    startVendorPayroll,
    vendorPayrollVisited,
    handleOpenAddPayrolls,
    handleOpenEditPayrollForm,
  } = props;

  useEffect(() => {
    if (G.isNilOrEmpty(prevPayrollGuid)) return;

    const payroll = R.find(R.propEq(prevPayrollGuid, GC.FIELD_GUID), itemList);

    handleOpenEditPayrollForm(prevPayrollGuid, payroll);
  }, [prevPayrollGuid]);

  useEffect(() => {
    if (G.isNilOrEmpty(nextPayrollGuid)) return;

    const payroll = R.find(R.propEq(nextPayrollGuid, GC.FIELD_GUID), itemList);

    handleOpenEditPayrollForm(nextPayrollGuid, payroll);
  }, [nextPayrollGuid]);

  const handleClickTab = (i: number) => {
    if (R.includes(i, [1, 2, 4, 5])) {
      const reportType = {
        1: GC.PIVOT_DRIVER_PAYROLL_REPORT,
        2: GC.PIVOT_PAYROLL_CHARGES_REPORT,
        4: GC.PIVOT_VENDOR_PAYROLL_REPORT,
        5: GC.PIVOT_VENDOR_PAYROLL_CHARGES_REPORT,
      }[i];

      startPivotTable(reportType);
    } else if (R.not(vendorPayrollVisited)) {
      startVendorPayroll();
    }

    handleSetActiveTab(i);
  };

  const customTitleComponent = (
    <Tabs2
      count={totalCount}
      activeTab={activeTab}
      tabs={H.getTabs(activeTab)}
      setActiveTab={handleClickTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
    />
  );

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          pt='5px'
          withCount={true}
          alignItems='flex-end'
          popperWithCount={true}
          type={GC.PAYROLL_REPORT}
          filterProps={FILTER_PARAMS}
          usedReport={selectedReport}
          hiddenRightFilterInfo={false}
          customTitleComponent={customTitleComponent}
          title={G.getWindowLocale('titles:payrolls', 'Payrolls')}
          additionalComponent={<FilterByTELReference {...props} />}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        <PayrollTable {...props} />
      </ZOrderWrapper>
      <AuthWrapper has={[PC.DRIVER_PAYROLL_WRITE]}>
        <PageActions
          listActions={getListActionsOpt(props)}
          shadowColor={G.getTheme('colors.light.darkGrey')}
          count={R.filter((item: Object) => item.selected, R.or(itemList, [])).length}
          mainAction={{
            action: handleOpenAddPayrolls,
            text: G.getWindowLocale('actions:create-payrolls-with-trips', 'Create Payrolls With Trips'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  configs: makeSelectPayrollConfigs(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  branchesTree: makeSelectBranchesTree(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  openedSidebar: makeSelectOpenedSidebar(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  documentTemplates: makeSelectDocumentTemplates(state),
  mailIntegrationType: makeSelectMailIntegrationType(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  vendorPayrollVisited: makeSelectVendorPayrollVisited(state),
  availableCustomExport: makeSelectAvailableCustomExport(state),
});

export default connect(mapStateToProps, {
  openModal,
  setReports,
  openLoader,
  closeModal,
  selectItem,
  closeLoader,
  addPayrolls,
  setUsedReport,
  startPivotTable,
  cleanQuickFilter,
  setTableTitleSort,
  getPayrollDetails,
  getItemListRequest,
  qbIIFImportRequest,
  startVendorPayroll,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  printPayrollRequest,
  customExportRequest,
  getPayrollXMLRequest,
  setQuickFilterParams,
  printByReportRequest,
  updatePayrollInvoices,
  getXMLByReportRequest,
  chaseCSVExportRequest,
  sendToQuickBookRequest,
  resetListAndPagination,
  exportReportDataRequest,
  removeInvoiceFromPayroll,
  updateDriverInvoiceRequest,
  changeDefaultReportRequest,
  handleUpdatePayrollRequest,
  deleteDriverPayrollsRequest,
  createDriversPayrollRequest,
})(enhance(DriverPayrolls));
