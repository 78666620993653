import * as R from 'ramda';
import { pure, compose, withProps } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  CLO_ROUTE_DEFAULT_ORGANIZE_BY,
  TEL_ROUTE_DEFAULT_ORGANIZE_BY,
} from '../../../helpers/options';
// utilities
import {
  defaultLoginScreenOptions,
  customerDefaultLoginScreenOptions,
} from '../../../utilities/routes';
// feature profile
import { getGeneralDetailsSettings } from '../settings';
//////////////////////////////////////////////////

export const withGeneralSettings = compose(
  withProps((props: Object) => {
    const { initialValues, dispatchingGroupOptions } = props;

    const isCarrier = G.isCurrentUserTypeCarrier();
    const status = R.path(['active'], initialValues);
    const isCustomer = G.isCurrentUserTypeCustomer();
    const userTypeDriver = R.propEq(GC.USER_TYPE_DRIVER, GC.FIELD_USER_TYPE, initialValues);
    const userTypeCarrier = R.propEq(GC.USER_ROLE_TYPE_CARRIER, GC.FIELD_USER_TYPE, initialValues);

    const title = `${G.getWindowLocale('titles:user-profile', 'User Profile')}: ${
      G.getUserLoginIdOrNames(initialValues)}`;

    const loginScreenOptions = G.ifElse(
      R.pathEq(GC.USER_TYPE_CUSTOMER, [GC.FIELD_USER_TYPE], initialValues),
      customerDefaultLoginScreenOptions,
      defaultLoginScreenOptions,
    );

    let telRouteDefaultGroupByOptionsToUse = TEL_ROUTE_DEFAULT_ORGANIZE_BY;

    if (userTypeCarrier) {
      telRouteDefaultGroupByOptionsToUse = R.without(
        R.of(Array, { label: 'Branch', value: GC.ORGANIZE_BY_BRANCH }),
        TEL_ROUTE_DEFAULT_ORGANIZE_BY,
      );
    }

    const localeOptions = [
      {
        value: GC.LANG_TYPE_GERMAN,
        label: G.getWindowLocale('titles:lang-type-german', 'German'),
      },
      {
        value: GC.LANG_TYPE_ENGLISH,
        label: G.getWindowLocale('titles:lang-type-english', 'English'),
      },
      {
        value: GC.LANG_TYPE_UKRAINIAN,
        label: G.getWindowLocale('titles:lang-type-ukrainian', 'Ukrainian'),
      },
    ];

    const fullLocaleOptions = [
      ...localeOptions,
      {
        value: GC.LANG_TYPE_SPANISH,
        label: G.getWindowLocale('titles:lang-type-spanish', 'Spanish'),
      },
    ];

    const optionsForSelect = {
      localeOptions,
      fullLocaleOptions,
      loginScreenOptions,
      dispatchingGroupOptions,
      cloRouteDefaultGroupByOptions: CLO_ROUTE_DEFAULT_ORGANIZE_BY,
      telRouteDefaultGroupByOptions: telRouteDefaultGroupByOptionsToUse,
    };

    const generalDetailsSettings = getGeneralDetailsSettings({
      status,
      isCarrier,
      isCustomer,
      userTypeDriver,
      userTypeCarrier,
    });

    return {
      ...generalDetailsSettings,
      title,
      optionsForSelect,
    };
  }),
  pure,
);
