import React from 'react';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { TextComponent } from '../../../components/text';
import useEditViewHook from '../../../hooks/use-edit-view';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, StickedBox, MainActionButton } from '../../../ui';
// feature payroll
import { PayrollChargeForm } from './payroll-charge-form';
import { UI_REPORT_NAME_PAYROLL_CHARGES } from '../constants';
import {
  getPayrollChargesReport,
  sortPayrollChargesReport,
  payrollChargesTableSettings,
  payrollChargesColumnSettings,
  getPayrollChargesDefaultReportFieldNames,
} from '../settings/charges-table-settings';
//////////////////////////////////////////////////

const reportName = UI_REPORT_NAME_PAYROLL_CHARGES;

const getChargeIndex = (charge: Object, currentCharges: Array) => {
  const chargeIdOrGuid = G.getIdOrGuidFromObject(charge);

  return R.findIndex(
    (item: Object) => R.or(
      R.propEq(chargeIdOrGuid, GC.FIELD_ID, item),
      R.propEq(chargeIdOrGuid, GC.FIELD_GUID, item),
    ),
    currentCharges,
  );
};

const enhance = compose(
  withHandlers({
    handleAddOrEditPayrollCharge: (props: Object) => ({ charge, isDeduction }: Object) => {
      const {
        type,
        values,
        openModal,
        closeModal,
        asyncConfigs,
        setFieldValue,
        glCodeMappings,
        accessorialsConfigs,
      } = props;

      const isEditMode = G.isNotNilAndNotEmpty(charge);
      const payrollCurrency = G.getPropFromObject(GC.FIELD_CURRENCY, values);
      const initialValues = G.ifElse(isEditMode, charge, { [GC.FIELD_CHARGE_DEDUCTION]: isDeduction });

      const submitAction = (newCharge: Object) => {
        closeModal();

        const currentCharges = R.pathOr([], [GC.FIELD_CHARGES], values);

        if (isEditMode) {
          const index = getChargeIndex(charge, currentCharges);

          return setFieldValue(GC.FIELD_CHARGES, R.update(index, newCharge, currentCharges));
        }

        setFieldValue(GC.FIELD_CHARGES, R.prepend(R.assoc(GC.FIELD_ID, G.genShortId(), newCharge), currentCharges));
      };

      const component = (
        <PayrollChargeForm
          type={type}
          submitAction={submitAction}
          asyncConfigs={asyncConfigs}
          initialValues={initialValues}
          glCodeMappings={glCodeMappings}
          payrollCurrency={payrollCurrency}
          accessorialsConfigs={accessorialsConfigs}
          vendorGuid={G.getPropFromObject(GC.FIELD_FLEET_VENDOR_GUID, values)}
          shouldGenerateId={G.isNotNilAndNotEmpty(G.getGuidFromObject(charge))}
          vendorBranchGuid={R.path([GC.SYSTEM_OBJECT_FLEET_VENDOR, GC.BRANCH_GUID], values)}
        />
      );

      const getTitle = isEditMode ? G.getEditTitle : G.getAddTitle;

      const modal = {
        p: 15,
        component,
        options: {
          width: 600,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
          title: getTitle(['titles:additional-deduction', 'Additional/Deduction']),
        },
      };

      openModal(modal);
    },
    handleRemovePayrollCharge: ({ values, setFieldValue }: Object) => (_: any, charge: Object) => {
      const currentCharges = R.pathOr([], [GC.FIELD_CHARGES], values);

      const index = getChargeIndex(charge, currentCharges);

      setFieldValue(GC.FIELD_CHARGES, R.remove(index, 1, currentCharges));
    },
    handleUpdatePayrollChargeComments: ({ values, setFieldValue }: Object) => (comments: any, charge: Object) => {
      const currentCharges = R.pathOr([], [GC.FIELD_CHARGES], values);

      const index = getChargeIndex(charge, currentCharges);

      setFieldValue(GC.FIELD_CHARGES, R.update(index, { ...charge, comments }, currentCharges));
    },
  }),
  pure,
);

const btnProps = { mr: 15, height: 30, width: 120, type: 'button' };

const Header = ({
  editViewBtnComponent,
  handleAddOrEditPayrollCharge,
}: Object) => (
  <Flex m='15px 0px 10px 15px'>
    <MainActionButton {...btnProps} onClick={() => handleAddOrEditPayrollCharge(false)}>
      {G.getWindowLocale('titles:add-additional', 'Add Additional')}
    </MainActionButton>
    <MainActionButton {...btnProps} onClick={() => handleAddOrEditPayrollCharge(true)}>
      {G.getWindowLocale('titles:add-deduction', 'Add Deduction')}
    </MainActionButton>
    { editViewBtnComponent }
  </Flex>
);

const commonTotalProps = {
  fontSize: 14,
  p: '2px 10px',
  fontWeight: 700,
  borderRadius: '3px',
  display: 'inline-block',
  color: G.getTheme('colors.white'),
  bg: G.getTheme('colors.light.blue'),
};

const Footer = ({
  currency,
  grandTotal,
  driverDeductionTotal,
  additionalPayrollPay,
  totalDriverTripCharges,
}: Object) => (
  <StickedBox p='15px 0' bottom='0px'>
    <Flex justifyContent='flex-end'>
      <TextComponent {...commonTotalProps}>
        {
          `${G.getWindowLocale('titles:driver-deduction', 'Driver Deduction')}: ${
            currency} ${G.toFixed(driverDeductionTotal)}`
        }
      </TextComponent>
      <TextComponent {...commonTotalProps} ml={15}>
        {
          `${G.getWindowLocale('titles:additional-payroll-pay', 'Additional Payroll Pay')}: ${
            currency} ${G.toFixed(additionalPayrollPay)}`
        }
      </TextComponent>
      <TextComponent {...commonTotalProps} ml={15}>
        {
          `${G.getWindowLocale('titles:total-driver-trip-charges', 'Total Driver Trip Charges')}: ${
            currency} ${G.toFixed(totalDriverTripCharges)}`
        }
      </TextComponent>
      <TextComponent {...commonTotalProps} ml={15}>
        {
          `${G.getWindowLocale('titles:gross-total', 'Gross Total')}: ${
            currency} ${G.toFixed(grandTotal)}`
        }
      </TextComponent>
    </Flex>
  </StickedBox>
);

const PayrollChargesTab = enhance((props: Object) => {
  const {
    type,
    values,
    maxHeight,
    widthToUse,
    asyncConfigs,
    withoutTotals,
    handleRemovePayrollCharge,
    handleAddOrEditPayrollCharge,
    handleUpdatePayrollChargeComments,
  } = props;

  const charges = R.pathOr([], [GC.FIELD_CHARGES], values);
  const payrollCurrency = G.getPropFromObject(GC.FIELD_CURRENCY, values);

  const chargesTotal = G.calculateChargesTotal(charges);
  const driverDeductionTotal = G.calculatePayrollDriverDeduction(charges);
  const additionalPayrollPay = R.subtract(chargesTotal, driverDeductionTotal);
  const payrollInvoicesTotal = R.pathOr(0, [GC.FIELD_PAYROLL_INVOICES_TOTAL], values);
  const grandTotal = R.subtract(R.add(payrollInvoicesTotal, additionalPayrollPay), driverDeductionTotal);

  const actionButtons = [
    {
      iconName: 'pencil',
      text: G.getWindowLocale('titles:edit', 'Edit'),
      action: (_: any, charge: Object) => handleAddOrEditPayrollCharge({ charge }),
    },
    {
      iconName: 'trash',
      action: handleRemovePayrollCharge,
      text: G.getWindowLocale('titles:delete', 'Delete'),
    },
  ];

  const isDriver = R.equals(type, GC.FIELD_DRIVER);
  const defaultReportFields = getPayrollChargesDefaultReportFieldNames(isDriver);

  const report = getPayrollChargesReport(isDriver, reportName);

  const {
    uiReportFields,
    handleOpenEditView,
    editViewBtnComponent,
  } = useEditViewHook({
    ...props,
    report,
    defaultReportFields,
    columnSettings: payrollChargesColumnSettings,
    disableFieldHandler: (name: string) => {
      if (G.isTrue(isDriver)) return R.equals(name, GC.FIELD_PAYED_FOR);

      return R.equals(name, GC.FIELD_DEDUCTION_FROM_VENDOR);
    },
  });

  const sortedReport = sortPayrollChargesReport(isDriver, uiReportFields, report);

  return (
    <Box width={widthToUse}>
      <Header
        isDriver={isDriver}
        editViewBtnComponent={editViewBtnComponent}
        handleAddOrEditPayrollCharge={(isDeduction: boolean) => handleAddOrEditPayrollCharge({ isDeduction })}
      />
      <Table
        itemList={charges}
        showEditView={true}
        report={sortedReport}
        shouldUseUIFilters={true}
        useSearchableColumns={true}
        withResizableColumns={true}
        actionButtons={actionButtons}
        useNewTitleFilterInputs={true}
        handleClickEditOrder={handleOpenEditView}
        columnSettings={payrollChargesColumnSettings}
        tableSettings={R.assoc('maxHeight', maxHeight, payrollChargesTableSettings)}
        filterProps={G.getFilterPropsFromColumnSettings(payrollChargesColumnSettings)}
        callbackData={{ asyncConfigs, payrollCurrency, handleUpdatePayrollChargeComments }}
      />
      {
        R.not(withoutTotals) &&
        <Footer
          grandTotal={grandTotal}
          currency={payrollCurrency}
          driverDeductionTotal={driverDeductionTotal}
          additionalPayrollPay={additionalPayrollPay}
          totalDriverTripCharges={payrollInvoicesTotal}
        />
      }
    </Box>
  );
});

export default PayrollChargesTab;
