import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const store = (state: Object) => state.payrolls;

const makeSelectReportStatus = () => createSelector(
  store,
  ({ reportPending }: Object) => reportPending,
);

const makeSelectAvailableReports = () => createSelector(
  store,
  ({ availableReports }: Object) => availableReports,
);

const makeSelectUsedReport = () => createSelector(
  store,
  ({ usedReport }: Object) => usedReport,
);

const makeSelectFilterParams = () => createSelector(
  store,
  ({ filterParams }: Object) => filterParams,
);

const makeSelectTableTitleFilters = () => createSelector(
  store,
  ({ tableTitleFilters }: Object) => tableTitleFilters,
);

const makeSelectTitleSortValues = () => createSelector(
  store,
  ({ titleSortValues }: Object) => titleSortValues,
);

const makeSelectItemList = () => createSelector(
  store,
  (state: Object) => R.sortBy(R.prop('index'), R.values(state.itemList)),
);

const makeSelectPagination = () => createSelector(
  store,
  ({ pagination }: Object) => pagination,
);

const makeSelectTotalCount = () => createSelector(
  store,
  ({ totalCount }: Object) => totalCount,
);

const makeSelectListLoading = () => createSelector(
  store,
  ({ loading }: Object) => loading,
);

const makeSelectPayrollConfigs = () => createSelector(
  store,
  ({ currentBranchConfigs }: Object) => {
    const autoPayrollNumber = G.getConfigValueFromStore(
      GC.INVOICE_TEL_PAYROLL_NUMBER_AUTOGENERATED,
      currentBranchConfigs,
    );

    const payrollDateRange = G.getConfigValueFromStore(
      GC.INVOICE_TEL_PAYROLL_DATE_RANGE_DAYS,
      currentBranchConfigs,
      30,
    );

    const payrollDayFrom = G.getConfigValueFromStore(
      GC.INVOICE_TEL_PAYROLL_DAY_FROM,
      currentBranchConfigs,
    );

    const payrollDayTo = G.getConfigValueFromStore(
      GC.INVOICE_TEL_PAYROLL_DAY_TO,
      currentBranchConfigs,
    );

    const invoiceFilterDateRange = G.getConfigValueFromStore(
      GC.INVOICE_TEL_PAYROLL_INVOICE_FILTER_DATE_RANGE_DAYS,
      currentBranchConfigs,
      30,
    );

    const defaultInvoiceStatusGuids = G.getConfigValueFromStore(
      GC.INVOICE_TEL_PAYROLL_DEFAULT_INVOICE_STATUSES,
      currentBranchConfigs,
      [],
    );

    return {
      payrollDayTo,
      payrollDayFrom,
      payrollDateRange,
      autoPayrollNumber,
      invoiceFilterDateRange,
      defaultInvoiceStatusGuids,
    };
  },
);

const makeSelectUpdatedInvoice = () => createSelector(
  store,
  ({ updatedInvoice }: Object) => updatedInvoice,
);

const makeSelectAvailableCustomExport = () => createSelector(
  store,
  ({ availableCustomExportTypes }: Object) => R.includes(
    GC.CUSTOM_EXCEL_EXPORT_TYPE_D_PLUS_S_DRIVER_DAILY_HOURS,
    R.or(availableCustomExportTypes, []),
  ),
);

const makeSelectRecallDocumentsGuid = () => createSelector(
  store,
  ({ recallDocumentsGuid }: Object) => recallDocumentsGuid,
);

export {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectPayrollConfigs,
  makeSelectUpdatedInvoice,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectRecallDocumentsGuid,
  makeSelectAvailableCustomExport,
};
