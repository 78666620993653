import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex } from '../../../ui';
//////////////////////////////////////////////////

const getTotals = (list: Array, payrollCurrency: string, isVendor: boolean = false) => {
  const tripsTotal = G.mathRoundNumber(R.compose(
    R.sum,
    R.map(({ total, currency, driversTotalInvoicePay }: Object) => {
      const totalToUse = isVendor ? driversTotalInvoicePay : total;

      if (G.notEquals(currency, payrollCurrency)) {
        return G.convertCurrencyByExchangeRate(totalToUse, currency, payrollCurrency);
      }

      return totalToUse;
    }),
  )(list));

  const customersTotal = G.mathRoundNumber(R.compose(
    R.sum,
    R.map(({ customerTotal, currency }: Object) => {
      if (G.notEquals(currency, payrollCurrency)) {
        return G.convertCurrencyByExchangeRate(customerTotal, currency, payrollCurrency);
      }

      return customerTotal;
    }),
  )(list));

  if (R.or(G.isZero(tripsTotal), G.isZero(customersTotal))) {
    return { tripsTotal, customersTotal, driverPay: '-' };
  }

  const driverPay = `${G.mathRoundNumber((tripsTotal / customersTotal) * 100)}%`;

  return { driverPay, tripsTotal, customersTotal };
};

const InvoicesTotals = ({ list, isVendor, payrollCurrency }: Object) => {
  if (G.isNilOrEmpty(list)) return null;

  const { driverPay, tripsTotal, customersTotal } = getTotals(list, payrollCurrency, isVendor);

  const currency = G.getCurrencySymbol(payrollCurrency);

  return (
    <Flex mr={15} ml='auto'>
      {
        G.isNotNaN(driverPay) && G.isNotNilAndNotEmpty(driverPay) &&
        <TextComponent
          p='2px 10px'
          fontSize={14}
          fontWeight={700}
          borderRadius='3px'
          display='inline-block'
          color={G.getTheme('colors.white')}
          bg={G.getTheme('colors.light.green')}
        >
          {
            `${G.getWindowLocale('titles:driver-pay', 'Driver Pay')}: ${driverPay}`
          }
        </TextComponent>
      }
      {
        G.isNotNaN(tripsTotal) && G.isNotNilAndNotEmpty(tripsTotal) &&
        <TextComponent
          ml={15}
          p='2px 10px'
          fontSize={14}
          fontWeight={700}
          borderRadius='3px'
          display='inline-block'
          color={G.getTheme('colors.white')}
          bg={G.getTheme('colors.light.blueGrey')}
        >
          {
            `${G.getWindowLocale('titles:driver-invoices', 'Driver Invoices')}: ${currency} ${G.toFixed(tripsTotal)}`
          }
        </TextComponent>
      }
      {
        G.isNotNaN(customersTotal) && G.isNotNilAndNotEmpty(customersTotal) &&
        <TextComponent
          ml={15}
          p='2px 10px'
          fontSize={14}
          fontWeight={700}
          borderRadius='3px'
          display='inline-block'
          color={G.getTheme('colors.white')}
          bg={G.getTheme('colors.light.blue')}
        >
          {
            `${G.getWindowLocale('titles:customers-total', 'Customers Total')}: ${
              currency} ${G.toFixed(customersTotal)}`
          }
        </TextComponent>
      }
    </Flex>
  );
};

export default InvoicesTotals;
